/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ClienteSalesforce interface.
 */
export function instanceOfClienteSalesforce(value) {
    return true;
}
export function ClienteSalesforceFromJSON(json) {
    return ClienteSalesforceFromJSONTyped(json, false);
}
export function ClienteSalesforceFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'idSalesforceCliente': json['idSalesforceCliente'] == null ? undefined : json['idSalesforceCliente'],
        'idCliente': json['idCliente'] == null ? undefined : json['idCliente'],
        'referente': json['referente'] == null ? undefined : json['referente'],
        'nome': json['nome'] == null ? undefined : json['nome'],
        'piva': json['piva'] == null ? undefined : json['piva'],
        'cf': json['cf'] == null ? undefined : json['cf'],
    };
}
export function ClienteSalesforceToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'idSalesforceCliente': value['idSalesforceCliente'],
        'idCliente': value['idCliente'],
        'referente': value['referente'],
        'nome': value['nome'],
        'piva': value['piva'],
        'cf': value['cf'],
    };
}
