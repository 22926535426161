import { SetStateAction, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../../../core/store/hooks';
import { FilterDtoTipologiaEnum, RevenueMonthDto } from '@api/client';
import { RevenueAllocation, RevenueEnum } from '../../_models/revenueAllocation';
import {
  currentProjectRevenueSelector,
  currentProjectSelector,
  currentProjectTotalCostSelector,
  currentProjectTypeSelector,
} from '../../../_redux/selectors';
import { ValueType } from '../../../../../../enums/global.enum';

const useGetProgressTableData = (
  revenueList: RevenueMonthDto[],
  refresh: boolean,
  setRefresh: React.Dispatch<SetStateAction<boolean>>,
  setYears: React.Dispatch<React.SetStateAction<Array<number | undefined>>>
) => {
  const totalRevenue = useAppSelector(currentProjectRevenueSelector) || 0;
  const totalCost = useAppSelector(currentProjectTotalCostSelector) || 0;
  const currentProjectType = useAppSelector(currentProjectTypeSelector);
  const currentProject = useAppSelector(currentProjectSelector);
  const [revenueAllocations, setRevenueAllocations] = useState<RevenueAllocation[] | null>(null);
  const [revenueAllocationsShown, setRevenueAllocationsShown] = useState<RevenueAllocation[] | null>(null);

  useEffect(() => {
    if (!revenueAllocations || refresh) {
      let mappedAllocations: RevenueAllocation[] = [];
      const mappedAllocationsValue: RevenueAllocation = {
        readOnly: false,
        canShowTotal: true,
        revenueEnum: RevenueEnum.RevenuePerMonth,
        revenueType: 'Revenue per month (€)',
        revenueUnit: ValueType.EURO,
        revenueList: revenueList.sort((a, b) => {
          // Ordina per anno
          if (a.year && b.year && a.year !== b.year) {
            return a.year - b.year;
          }
          // Se gli anni sono uguali, ordina per mese
          return (a.month ?? 0) - (b.month ?? 0);
        }),
      };
      const mappedAllocationsPercentage: RevenueAllocation = {
        readOnly: false,
        canShowTotal: true,
        revenueEnum: RevenueEnum.RevenuePerMonthPercentage,
        revenueType: 'Revenue per month (%)',
        revenueUnit: ValueType.PERCENTUALE,
        revenueList: revenueList
          .map((el) => ({
            ...el,
            amount: ((el.amount || 0) / totalRevenue) * 100,
          }))
          .sort((a, b) => {
            // Ordina per anno
            if (a.year && b.year && a.year !== b.year) {
              return a.year - b.year;
            }
            // Se gli anni sono uguali, ordina per mese
            return (a.month ?? 0) - (b.month ?? 0);
          }),
      };
      const mappedAllocationsCumuativePercentage: RevenueAllocation = {
        readOnly: true,
        canShowTotal: true,
        revenueEnum: RevenueEnum.CumulativeRevenuePercentage,
        revenueType: 'Cumulative Revenue (%)',
        revenueUnit: ValueType.PERCENTUALE,
        revenueList: revenueList
          .map((el, index) => ({
            ...el,
            amount: revenueList.slice(0, index + 1).reduce((acc, curr) => acc + (curr.amount || 0), 0),
          }))
          .map((el) => ({
            ...el,
            amount: (el.amount / totalRevenue) * 100,
          }))
          .sort((a, b) => {
            // Ordina per anno
            if (a.year && b.year && a.year !== b.year) {
              return a.year - b.year;
            }
            // Se gli anni sono uguali, ordina per mese
            return (a.month ?? 0) - (b.month ?? 0);
          }),
      };
      // mapped costs percentage
      const mappedAllocationsCostsPercentage: RevenueAllocation = {
        readOnly: true,
        canShowTotal: true,
        revenueEnum: RevenueEnum.CostsPerMonthPercentage,
        revenueType: 'Costs per month (%)',
        revenueUnit: ValueType.PERCENTUALE,
        revenueList:
          currentProject?.revenuePlan
            ?.map((el) => ({
              ...el,
              amount: totalCost ? ((el.actualCost || 0) / totalCost) * 100 : 0,
            }))
            ?.sort((a, b) => {
              // Ordina per anno
              if (a.year && b.year && a.year !== b.year) {
                return a.year - b.year;
              }
              // Se gli anni sono uguali, ordina per mese
              return (a.month ?? 0) - (b.month ?? 0);
            }) || [],
      };
      // mappped margin percentage
      const mappedAllocationsMarginPercentage: RevenueAllocation = {
        readOnly: true,
        canShowTotal: false,
        revenueEnum: RevenueEnum.MarginPerMonthPercentage,
        revenueType: 'Margin per month (%)',
        revenueUnit: ValueType.PERCENTUALE,
        revenueList:
          revenueList
            ?.map((el) => ({
              ...el,
              amount: el.amount ? (((el.amount || 0) - getCostForMonthYear(el)) / (el.amount || 0)) * 100 : 0,
            }))
            ?.sort((a, b) => {
              // Ordina per anno
              if (a.year && b.year && a.year !== b.year) {
                return a.year - b.year;
              }
              // Se gli anni sono uguali, ordina per mese
              return (a.month ?? 0) - (b.month ?? 0);
            }) || [],
      };
      mappedAllocations.push(mappedAllocationsPercentage);
      mappedAllocations.push(mappedAllocationsValue);
      mappedAllocations.push(mappedAllocationsCumuativePercentage);
      mappedAllocations.push(mappedAllocationsCostsPercentage);
      mappedAllocations.push(mappedAllocationsMarginPercentage);
      setRevenueAllocations(mappedAllocations);
      setRevenueAllocationsShown(mappedAllocations);

      const yearsToSet: Array<number | undefined> = [];

      mappedAllocations?.[0]?.revenueList.forEach((alloc) => {
        if (!yearsToSet.includes(alloc?.year)) {
          yearsToSet.push(alloc?.year);
        }
      });
      setYears(yearsToSet);
      setRefresh(false);
    }
  }, [revenueList, refresh]);

  const getCostForMonthYear = (revenue: RevenueMonthDto) => {
    const cost = currentProject?.revenuePlan?.find(
      (el) => el.year === revenue.year && el.month === revenue.month
    )?.actualCost;
    return cost || 0;
  };

  return { revenueAllocations, revenueAllocationsShown, setRevenueAllocations, setRevenueAllocationsShown };
};

export default useGetProgressTableData;
