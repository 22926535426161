import { TabsProps } from 'antd';
import { ActiveCycleTabs } from 'enums/project.enum';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const useActiveCycleTabs = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<string>(ActiveCycleTabs.ORDERS);

  const activeCycleTabs: TabsProps['items'] = [
    {
      key: ActiveCycleTabs.ORDERS,
      label: t('Orders'),
    },
    { key: ActiveCycleTabs.BILLS, label: t('Bills') },
  ];

  const onChangeTab = (key: string) => {
    setSelectedTab(key);
  };

  return { selectedTab, activeCycleTabs, onChangeTab };
};

export default useActiveCycleTabs;
