import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../core/store/hooks';
import { useTranslation } from 'react-i18next';
import AppCard from '../../../../../shared/design-system/components/app-card';
import LinksButton from '../../../../../shared/design-system/components/links-button';
import { useEffect, useState } from 'react';
import AppAccordion from '../../../../../shared/design-system/components/app-accordion';
import AccordionForm, {
  projectListFormData,
} from '../../../../../shared/design-system/components/app-accordion/_components/accordion-form';
import useAccordionSubmit from '../../../../../shared/design-system/components/app-accordion/_hooks/useFormSubmit';
import { ContractOrderOutputDto } from '@api/client';
import NoData from '../../../../../shared/design-system/components/no-data-box';
import React from 'react';
import { LocationFilterEnum } from '../../../../../enums/global.enum';
import useGetAllUsers from '../../../../../shared/_hooks/useGetAllUsers';
import {
  activeCycleFattureEmesseSelector,
  activeCycleOrdersAttiviSelector,
  activeCycleProjectsAssociatedSelector,
  activeOrdersSfSelector,
  associableInvoicesActiveCycleSelector,
  associableProjectsActiveCycleSelector,
} from '../_redux/selectors';
import { getActiveCycleFattureEmesse, searchActiveOrders, searchActiveOrdersSf } from '../_redux/actions';
import ActiveCycleOrderData from './_components/active-order-data';
import ActiveOrderDataTable from './_components/active-order-data-table';
import ActiveOrderAssociatedProjectsTable from './_components/active-order-associated-projects-table';
import ActiveOrderAssociatedInvoicesTable from './_components/active-order-associated-invoices-table';
import AssociableProjectsTable from './_components/associable-projects-table';
import { Button } from 'react-bootstrap';
import AssociableInvoicesFilterForm, {
  associableInvoicesFormData,
} from './_components/associable-invoices-filter-form';
import useFormAssociableActiveInvoices from 'shared/design-system/components/app-accordion/_hooks/useFormAssociableActiveInvoices';

const OrderDetail: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams<{ id: any }>();
  const allUsers = useGetAllUsers();
  const [currentOrder, setCurrentOrder] = useState<ContractOrderOutputDto | undefined>(undefined);
  const filteredOrders = useAppSelector(activeOrdersSfSelector);
  const projectsAssociated = useAppSelector(activeCycleProjectsAssociatedSelector);
  const orderAttivo = useAppSelector(activeCycleOrdersAttiviSelector);
  const fattureEmesse = useAppSelector(activeCycleFattureEmesseSelector);
  const associableProjects = useAppSelector(associableProjectsActiveCycleSelector);
  const associableInvoices = useAppSelector(associableInvoicesActiveCycleSelector);

  useEffect(() => {
    if (filteredOrders) {
      const order = filteredOrders.contractOrderOutputDtoList?.find((order) => order.pkOrderSF === id);
      setCurrentOrder(order);
      dispatch(searchActiveOrders({ contractOrderSearchDto: { cicloAttivoOrderNumber: id } }));
    } else {
      dispatch(searchActiveOrdersSf({ contractOrderSFSearchInputDto: { orderId: id } }));
    }
  }, [filteredOrders]);

  useEffect(() => {
    const orderId = orderAttivo?.[0]?.id;
    if (orderId) {
      dispatch(
        getActiveCycleFattureEmesse({
          request: { fatturaEmessaSearchDto: { numeroOrdine: String(orderId), paging: { size: 9999, page: 0 } } },
          type: 'associated',
        })
      );
    }
  }, [orderAttivo]);

  useEffect(() => {
    console.log(associableProjects);
  }, [associableProjects]);

  const [isAssociateNewProjectVisible, setIsAssociateNewProjectVisible] = useState(false);
  const [isAssociateNewInvoiceVisible, setIsAssociateNewInvoiceVisible] = useState(false);

  // INIZO - Form filter projects
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { onSubmit, onReset, chipsFormData, setChipsFormData, reset } = useAccordionSubmit(
    projectListFormData,
    setIsOpen,
    0,
    true
  );
  const [controlledDate, setControlledDate] = useState<any>(null);
  const handleReset = () => {
    onReset();
    controlledDate && setControlledDate([]);
    chipsFormData && setChipsFormData([]);
  };
  // FINE - Form filter projects

  // INIZIO - Form filter invoices
  const [isOpenInvoice, setIsOpenInvoice] = useState<boolean>(true);
  const { onSubmitInvoices, onResetInvoices, chipsFormInvoicesData, setChipsFormInvoicesData, resetInvoice } =
    useFormAssociableActiveInvoices(
      associableInvoicesFormData,
      setIsOpenInvoice,
      currentOrder?.startDate || new Date(),
      orderAttivo?.[0]?.fkCliente
    );
  const handleResetInvoices = () => {
    onResetInvoices();
    chipsFormInvoicesData && setChipsFormInvoicesData([]);
  };
  // FINE - Form filter invoices

  return (
    <>
      <h4 className="text-gray-800 my-0">{t('Order')}</h4>

      {!currentOrder && (
        <AppCard title={t('Order data')} className="border-left-primary mt-3">
          <div className="mt-3">
            <NoData msg={t('No order found')} />
          </div>
        </AppCard>
      )}

      {currentOrder && (
        <>
          <ActiveCycleOrderData order={currentOrder} allUsers={allUsers.allUsers} />
          <br />
          <b className="text-blue-links">{t('Economics data')}</b>
          <ActiveOrderDataTable order={currentOrder} />

          {/* ASSOCIATE PROJECT BUTTON */}
          <div className="d-flex justify-content-between my-3">
            <b className="text-blue-links">{t('Projects')}</b>
            <div>
              <LinksButton
                className="btn-sm btn-primary btn-teal px-4 ms-auto"
                onClick={() => {
                  setIsAssociateNewProjectVisible(true);
                }}
              >
                {t('+ Associate project')}
              </LinksButton>
            </div>
          </div>

          {/* ASSOCIATED PROJECTS */}
          <ActiveOrderAssociatedProjectsTable
            projects={projectsAssociated}
            orderId={currentOrder.pkOrderSF}
            numericOrderId={orderAttivo?.[0]?.id}
          />

          {/* ASSOCIATE NEW PROJECT CARD */}
          {isAssociateNewProjectVisible && (
            <AppCard
              title="Associate new project"
              className="border-left-primary mt-3"
              headerClassname="d-flex justify-content-between"
              headerElement={
                <Button
                  className="btn text-blue-links p-0"
                  type="button"
                  variant="icon"
                  onClick={() => setIsAssociateNewProjectVisible(false)}
                >
                  <i className="fas fa-x text-blue-links" />
                </Button>
              }
            >
              <AppAccordion
                formData={projectListFormData}
                location={LocationFilterEnum.PROJECT_LIST}
                setIsOpen={setIsOpen}
                isOpen={isOpen}
                chipsFormData={chipsFormData}
                setChipsFormData={setChipsFormData}
                reset={reset}
              >
                <AccordionForm
                  onSubmit={onSubmit}
                  handleReset={handleReset}
                  setControlledDate={setControlledDate}
                  // onReset={onReset} control={control} register={register}
                />
              </AppAccordion>
              <AssociableProjectsTable projects={associableProjects} orderId={currentOrder.pkOrderSF} />
            </AppCard>
          )}

          {/* ASSOCIATE INVOICE BUTTON */}
          <div className="d-flex justify-content-between my-3">
            <b className="text-blue-links">{t('Invoices issued')}</b>
            <div>
              <LinksButton
                className="btn-sm btn-primary btn-teal px-4 ms-auto"
                disabled={!projectsAssociated.length}
                onClick={() => {
                  setIsAssociateNewInvoiceVisible(!isAssociateNewInvoiceVisible);
                }}
              >
                {t('+ Associate invoice')}
              </LinksButton>
            </div>
          </div>

          {/* ASSOCIATED INVOICES */}
          <ActiveOrderAssociatedInvoicesTable invoices={fattureEmesse} showAddInvoice={false} />

          {/* ASSOCIATE NEW INVOICE CARD */}
          {isAssociateNewInvoiceVisible && (
            <AppCard
              title="Associate new invoice"
              className="border-left-primary mt-3"
              headerClassname="d-flex justify-content-between"
              headerElement={
                <Button
                  className="btn text-blue-links p-0"
                  type="button"
                  variant="icon"
                  onClick={() => setIsAssociateNewInvoiceVisible(false)}
                >
                  <i className="fas fa-x text-blue-links" />
                </Button>
              }
            >
              <AppAccordion
                formData={associableInvoicesFormData}
                location={LocationFilterEnum.ACTIVE_CYCLE_INVOICES}
                setIsOpen={setIsOpenInvoice}
                isOpen={isOpenInvoice}
                chipsFormData={chipsFormInvoicesData}
                setChipsFormData={setChipsFormInvoicesData}
                reset={resetInvoice}
              >
                <AssociableInvoicesFilterForm
                  onSubmitInvoices={onSubmitInvoices}
                  handleResetInvoices={handleResetInvoices}
                />
              </AppAccordion>
              <ActiveOrderAssociatedInvoicesTable
                invoices={associableInvoices}
                idOrdine={orderAttivo?.[0]?.id}
                idOrdineSf={currentOrder.pkOrderSF}
                associatedProjects={projectsAssociated}
                showAddInvoice={true}
              />
            </AppCard>
          )}
        </>
      )}
    </>
  );
};

export default OrderDetail;
