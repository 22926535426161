import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../core/store';

const fattureReducer = (state: RootState) => state.private.fatture;

export const fattureEmesseSelector = createSelector(fattureReducer, (fatture) => fatture.fattureEmesse);
export const fattureRicevuteSelector = createSelector(fattureReducer, (fatture) => fatture.fattureRicevute);
export const filtersEmesseSelector = createSelector(fattureReducer, (fatture) => fatture.filtersEmesse);
export const filtersEmesseUsedSelector = createSelector(fattureReducer, (fatture) => fatture.filtersEmesseUsed);
export const filtersRicevuteSelector = createSelector(fattureReducer, (fatture) => fatture.filtersRicevute);
export const filtersRicevuteUsedSelector = createSelector(fattureReducer, (fatture) => fatture.filtersRicevuteUsed);
export const activeOrdersSelector = createSelector(fattureReducer, (fatture) => fatture.activeOrders);
export const projectsForActiveBillsSelector = createSelector(
  fattureReducer,
  (fatture) => fatture.projectsForActiveBills
);
export const projectsForPassiveBillsSelector = createSelector(
  fattureReducer,
  (fatture) => fatture.projectsForPassiveBills
);
