import { FatturaEmessaDto, ProjectLightInfoDto } from '@api/client';
import { Select } from 'antd';
import { useAppDispatch } from 'core/store/hooks';
import { associateFatturaProject } from 'pages/private/active-cycle/active-cycle-bills/_redux/actions';
import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';
import NoData from 'shared/design-system/components/no-data-box';
import { formatCurrency } from 'shared/utils/common.utils';
import { searchActiveOrders } from '../../../_redux/actions';

interface Props {
  invoices: FatturaEmessaDto[];
  associatedProjects?: ProjectLightInfoDto[];
  idOrdine?: number | undefined;
  idOrdineSf?: string | undefined;
  showAddInvoice?: boolean;
}

type FatturaEmessaDtoWithProject = FatturaEmessaDto & { projectId: number | undefined };

const ActiveOrderAssociatedInvoicesTable: React.FC<Props> = ({
  invoices,
  associatedProjects,
  idOrdine,
  idOrdineSf,
  showAddInvoice,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [invoicesList, setInvoicesList] = useState<FatturaEmessaDtoWithProject[]>([]);

  useEffect(() => {
    if (invoices) {
      setInvoicesList(invoices.map((invoice) => ({ ...invoice, projectId: undefined })));
    }
  }, [invoices]);

  const handleAssociateInvoice = (invoice: FatturaEmessaDtoWithProject) => {
    dispatch(
      associateFatturaProject({
        associaFattureProgettoDtoList: {
          fattureProgettoDtoList: [
            { pkFattura: invoice.idFattura, dettagli: [{ idProject: invoice.projectId, idOrdine }] },
          ],
        },
      })
    )
      .unwrap()
      .then(() => {
        AppToastService.success(t(`Invoice associated successfully`));
        dispatch(searchActiveOrders({ contractOrderSearchDto: { cicloAttivoOrderNumber: idOrdineSf } }));
      });
  };

  const handleChangeProjectId = (projectId: number | undefined, invoiceId: number | undefined) => {
    setInvoicesList(
      invoicesList.map((invoice) => {
        if (invoice.numeroFattura === invoiceId) {
          return { ...invoice, projectId };
        }
        return invoice;
      })
    );
  };

  if (invoicesList.length) {
    return (
      <div className="table-responsive table-bordered mt-3">
        <Table className={'mt-0 mb-0'}>
          <thead className="bg-red">
            <tr>
              <th className={`align-middle`} scope="col">
                <span>{'N.Invoice'}</span>
              </th>
              {!showAddInvoice && (
                <th className={`align-middle`} scope="col">
                  <span>{'ID Project'}</span>
                </th>
              )}
              <th className={`align-middle`} scope="col">
                <span>{'Amount'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'Invoice date'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'Typology'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'Expiry date'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'Payment date'}</span>
              </th>
              <th className={`align-middle`} scope="col" style={{ width: '350px' }}>
                <span>{'Description'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'Status'}</span>
              </th>
              {showAddInvoice && (
                <>
                  <th className={`align-middle`} scope="col" style={{ maxWidth: '50px' }}>
                    <span>{'Project'}</span>
                  </th>
                  <th className={`align-middle`} scope="col" style={{ maxWidth: '50px' }}>
                    <span>{'Add'}</span>
                  </th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {invoicesList.map((invoice) => (
              <tr key={`invoice-${invoice.numeroFattura}`}>
                <td className="text-end">
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{invoice.numeroFattura}</p>
                  </span>
                </td>
                {!showAddInvoice && (
                  <td className="text-end">
                    <span className="d-inline-flex align-items-center">
                      <p className="my-auto">{invoice.fkProgettoIntranet}</p>
                    </span>
                  </td>
                )}
                <td className="text-end">
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{formatCurrency(invoice.importo)}€</p>
                  </span>
                </td>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{invoice.dataFattura?.toLocaleDateString('it-IT') || '-'}</p>
                  </span>
                </td>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{invoice.tipoFattura}</p>
                  </span>
                </td>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{invoice.dataScadenza?.toLocaleDateString('it-IT') || '-'}</p>
                  </span>
                </td>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{invoice.dataPagamento?.toLocaleDateString('it-IT') || '-'}</p>
                  </span>
                </td>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto" title={invoice.descrizione || '-'}>
                      {invoice.descrizione
                        ? `${invoice.descrizione.slice(0, 100)}${invoice.descrizione.length > 100 ? '…' : ''}`
                        : '-'}
                    </p>
                  </span>
                </td>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{invoice.fattStatoContabile || '-'}</p>
                  </span>
                </td>
                {showAddInvoice && (
                  <>
                    <td>
                      <Select
                        className="w-100"
                        placeholder={'Select project'}
                        showSearch
                        optionFilterProp="children"
                        filterOption={true}
                        value={invoice.projectId}
                        allowClear
                        variant="borderless"
                        onChange={(event) => handleChangeProjectId(event, invoice.numeroFattura)}
                      >
                        {associatedProjects?.map((el) => (
                          <Select.Option key={`project-to-associate${el.projectId}`} value={el.projectId}>
                            {el.intranetId}
                          </Select.Option>
                        ))}
                      </Select>
                    </td>
                    <td className="text-center">
                      <Button
                        className="btn text-blue-links p-0"
                        type="button"
                        variant="icon"
                        disabled={!invoice.projectId}
                        onClick={() => handleAssociateInvoice(invoice)}
                      >
                        <i className="fas fa-plus-circle text-blue-links" />
                      </Button>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center mt-3">
        <NoData msg={t('No invoices found')} />
      </div>
    );
  }
};

export default ActiveOrderAssociatedInvoicesTable;
