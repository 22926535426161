import { useEffect } from 'react';
import { trackUserIdGa4 } from 'shared/utils/gtag';
import { TrackPagesGA4Enum } from 'enums/global.enum';
import { Tabs } from 'antd';
import { ActiveCycleTabs } from 'enums/project.enum';
// import ActiveCycleBills from './active-cycle-bills';
import useActiveCycleTabs from './_hooks/useActiveCycleTabs';
import ActiveCycleOrderList from './active-cycle-order-list';
import ActiveCycleBills from './active-cycle-bills';

const PassiveCycle: React.FC = () => {
  const { selectedTab, activeCycleTabs, onChangeTab } = useActiveCycleTabs();

  useEffect(() => {
    trackUserIdGa4(TrackPagesGA4Enum.ACTIVE_CYCLE);
  }, []);

  return (
    <>
      <Tabs defaultActiveKey={ActiveCycleTabs.ORDERS} items={activeCycleTabs} onChange={onChangeTab} />
      {selectedTab === ActiveCycleTabs.ORDERS && <ActiveCycleOrderList />}
      {selectedTab === ActiveCycleTabs.BILLS && <ActiveCycleBills />}
    </>
  );
};

export default PassiveCycle;
