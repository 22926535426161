import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Select, Tooltip } from 'antd';
import { Table } from 'react-bootstrap';
import { ordersForBillsSelector } from '../_redux/selectors';
import {
  filtersRicevuteUsedSelector,
  projectsForPassiveBillsSelector,
} from '../../active-cycle/active-cycle-bills/_redux/selectors';
import useGetAndMapReceivedBills, { FieldEnum } from './_hooks/useGetAndMapReceivedBills';
import { searchOrders, searchOrdersForBills } from '../_redux/actions';
import { getFattureRicevute, getProjectsForPassiveBills } from '../../active-cycle/active-cycle-bills/_redux/actions';
import { useAppDispatch, useAppSelector } from '../../../../core/store/hooks';
import useFormFatture from '../../../../shared/design-system/components/app-accordion/_hooks/useFormFatture';
import FattureForm, { fattureFiltersFormData } from '../../../../shared/_components/fatture-filters';
import AppAccordion from '../../../../shared/design-system/components/app-accordion';
import { LocationFilterEnum } from '../../../../enums/global.enum';
import AppCard from '../../../../shared/design-system/components/app-card';
import NoData from '../../../../shared/design-system/components/no-data-box';
import { DataTable } from '../../project-list/project-list-table';
import ServerSidePaginator from '../../../../shared/design-system/components/app-server-side-paginator';
import { filterOption } from '../../../../shared/utils/common.utils';
import { ContractOrderOutputDto, ContractOrderSearchDto, SearchFattureRicevuteRequest } from '@api/client';
import useHandleChangePassiveCycleAssociation from './_hooks/useHandleChangePassiveCycleAssociation';
import AssociateCostsToBillModal from './_conponents/associate-costs-to-bill-modal';
import { AppToastService } from '../../../../shared/design-system/components/app-toast/app-toast.service';
import useGetPassiveCycleOptions from '../_hooks/useGetPassiveCycleOptions';

const PassiveCycleBills: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [showAsscoiationModal, setShowAssociationModal] = useState<boolean>(false);
  const [indexBill, setIndexBill] = useState<number | undefined>(undefined);
  const [passiveOrderDetail, setPassiveOrderDetail] = useState<ContractOrderOutputDto>();
  const passiveOrders = useAppSelector(ordersForBillsSelector);
  const projectsForPassiveBills = useAppSelector(projectsForPassiveBillsSelector);
  const filtriUsed = useAppSelector(filtersRicevuteUsedSelector);
  const { receivedBills, setReceivedBills, filteredBills } = useGetAndMapReceivedBills(
    passiveOrders,
    projectsForPassiveBills
  );
  const { handleChangeValue } = useHandleChangePassiveCycleAssociation(setReceivedBills);
  const { mappedPassiveCycleOptions } = useGetPassiveCycleOptions();

  useEffect(() => {
    if (!passiveOrders.length) dispatch(searchOrdersForBills());
  }, [passiveOrders]);

  useEffect(() => {
    if (!projectsForPassiveBills.length) dispatch(getProjectsForPassiveBills());
  }, [projectsForPassiveBills]);

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const { onSubmit, onReset, chipsFormData, setChipsFormData, reset, filterParams } = useFormFatture(
    fattureFiltersFormData,
    'passive'
  );

  const [controlledDate, setControlledDate] = useState<any>(null);

  const handleReset = () => {
    onReset();
    controlledDate && setControlledDate([]);
    chipsFormData && setChipsFormData([]);
  };

  const isActionDisabled = (indexBill: number) => {
    return (
      receivedBills[indexBill]['numeroOrdine'].value === undefined
      // || receivedBills[indexBill]['progetti'].value === undefined
    );
  };

  const changePage = (page: number) => {
    let filters = { ...filtriUsed };
    if (filters.paging) {
      filters.paging = { ...filters.paging, page: page };
    }
    const payload: SearchFattureRicevuteRequest = {
      fatturaRicevutaSearchDto: filters!,
    };
    dispatch(getFattureRicevute(payload));
  };

  const openAssociationModal = (numeroOrdine: string, indexBill: number) => {
    setIndexBill(indexBill);
    const payload: ContractOrderSearchDto = {
      idExternalContract: numeroOrdine,
    };
    dispatch(searchOrders({ request: { contractOrderSearchDto: payload }, skipSave: true }))
      .unwrap()
      .then((res) => {
        if (res && res.contractOrderOutputDtoList && res.contractOrderOutputDtoList.length > 0) {
          setPassiveOrderDetail(res.contractOrderOutputDtoList[0]);
          if (res.contractOrderOutputDtoList[0].projectExternalCost?.length) {
            setShowAssociationModal(true);
          } else {
            AppToastService.error('The selected order has no associated projects');
          }
        }
      });
  };

  return (
    <>
      <h4 className="mt-3">Passive cycle bills</h4>
      <AppAccordion
        formData={fattureFiltersFormData}
        location={LocationFilterEnum.BILLS_PASSIVE_CYCLE}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        chipsFormData={chipsFormData}
        setChipsFormData={setChipsFormData}
        reset={reset}
      >
        <FattureForm
          onSubmit={onSubmit}
          handleReset={handleReset}
          setControlledDate={setControlledDate}
          mappedPassiveCycleOptions={mappedPassiveCycleOptions}
          type="passive"
        />
      </AppAccordion>

      <AppCard title={t('Bills')}>
        {receivedBills.length === 0 ? (
          <NoData msg={t('No bill found')} />
        ) : (
          <div className="table-responsive table-bordered">
            <Table className={'mt-0 mb-0'}>
              <thead className="bg-red">
                <tr>
                  {Object.keys(receivedBills[0]).map((el) => {
                    if (receivedBills[0][el].visible) {
                      return (
                        <th className={`${receivedBills[0][el].className} align-middle`} key={el} scope="col">
                          <span>{receivedBills[0][el].label}</span>
                        </th>
                      );
                    }
                  })}
                </tr>
              </thead>
              <tbody>
                {receivedBills.map((e: DataTable, indexBill: number) => (
                  <tr key={'row-' + indexBill}>
                    {Object.keys(receivedBills[0]).map((h) => {
                      if (receivedBills[0][h].visible) {
                        return (
                          <td
                            className={`${receivedBills[0][h].className}`}
                            key={`${h}-${indexBill}`}
                            style={{ maxWidth: e[h].width, minWidth: e[h].width }}
                          >
                            {' '}
                            {e[h].isButton && (
                              <div className="d-flex align-items-center justify-content-center">
                                <Tooltip placement="top" color="white" title={e[h]?.tooltip}>
                                  <button
                                    onClick={() => {
                                      openAssociationModal(e.numeroOrdine.value as string, indexBill);
                                    }}
                                    className="btn-icon"
                                    disabled={isActionDisabled(indexBill)}
                                  >
                                    <i
                                      className={`${e[h].icon} ${
                                        isActionDisabled(indexBill) ? 'text-disabled' : 'text-blue-links'
                                      } me-1`}
                                    ></i>
                                  </button>
                                </Tooltip>
                              </div>
                            )}
                            {e[h].isSelect && (
                              <Select
                                className="w-100"
                                placeholder={e[h].tooltip}
                                showSearch
                                mode={e[h].id === FieldEnum.PROJECT ? 'multiple' : undefined}
                                optionFilterProp="children"
                                filterOption={filterOption}
                                value={e[h].value}
                                allowClear
                                variant="borderless"
                                onChange={(event) => handleChangeValue(event, e[h].id, indexBill)}
                              >
                                {e[h].options?.map((el) => (
                                  <Select.Option
                                    key={`${el.projectId ?? el.idExternalContract}-${indexBill}`}
                                    value={el.projectId ?? el.idExternalContract}
                                  >
                                    {el.projectName ?? el.idExternalContract}
                                  </Select.Option>
                                ))}
                              </Select>
                            )}
                            {!e[h].isButton && !e[h].isSelect && (
                              <span className="d-inline-flex">
                                <p className="my-auto">{e[h].value ?? ''}</p>
                              </span>
                            )}
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        )}
        {receivedBills?.length > 0 ? (
          <ServerSidePaginator
            currentPage={filteredBills?.page || 0}
            totalPages={filteredBills?.totalPages || 0}
            elementsToShow={10}
            totalElements={filteredBills?.totalElements}
            onPageChange={(e) => changePage(e)}
          />
        ) : (
          ''
        )}
      </AppCard>
      <AssociateCostsToBillModal
        show={showAsscoiationModal}
        setShow={setShowAssociationModal}
        receivedBills={receivedBills}
        indexBill={indexBill}
        passiveOrderDetail={passiveOrderDetail}
      />
    </>
  );
};

export default PassiveCycleBills;
