/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const WarningDtoLevelEnum = {
    Info: 'INFO',
    Warning: 'WARNING',
    Error: 'ERROR'
};
/**
 * Check if a given object implements the WarningDto interface.
 */
export function instanceOfWarningDto(value) {
    return true;
}
export function WarningDtoFromJSON(json) {
    return WarningDtoFromJSONTyped(json, false);
}
export function WarningDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'level': json['level'] == null ? undefined : json['level'],
        'code': json['code'] == null ? undefined : json['code'],
        'message': json['message'] == null ? undefined : json['message'],
        'details': json['details'] == null ? undefined : json['details'],
    };
}
export function WarningDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'level': value['level'],
        'code': value['code'],
        'message': value['message'],
        'details': value['details'],
    };
}
