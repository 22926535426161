import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FilterDto } from '@api/client';
import { useAppDispatch, useAppSelector } from '../../../../../core/store/hooks';
import {
  getFilteredProjects,
  getProjectSearchFilter,
  saveFiltersUsed,
} from '../../../../../pages/private/project-list/_redux/actions';
import {
  filtersUsedSelector,
  filtriProgettiSelector,
} from '../../../../../pages/private/project-list/_redux/selectors';
import { StorageKeysEnum } from '../../../../../enums/storageKeys.enum';
import { getOtherAssociatibleCosts } from '../../../../../pages/private/passive-cycle/_redux/actions';
import { getAssociableProjects } from 'pages/private/active-cycle/active-cycle-order-list/_redux/actions';

const useAccordionSubmit = (
  initialData: any,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  idContract?: number,
  isAssociableProjects?: boolean
) => {
  const dispatch = useAppDispatch();
  const isSubmitting = useRef(false);
  const filterUsed = useAppSelector(filtersUsedSelector);
  const filters = useAppSelector(filtriProgettiSelector);
  const [chipsFormData, setChipsFormData] = useState<any[]>([]);
  const [filterParams, setFilterParams] = useState<any[]>([]);
  const { handleSubmit, control, register, reset, formState } = useForm({
    mode: 'onChange',
    defaultValues: initialData,
  });

  useEffect(() => {
    if (!filters) {
      dispatch(getProjectSearchFilter());
    }
    const data: any = filterUsed;
    const formDataArray = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (Array.isArray(data[key])) {
          const isRangeModified = data[key].some((value: any, index: any) => value !== initialData[key][index]);
          if (isRangeModified) {
            formDataArray.push([key, data[key]]);
          }
        } else if (data[key] !== initialData[key]) {
          formDataArray.push([key, data[key]]);
        }
      }
    }
    setChipsFormData([...formDataArray]);
  }, [dispatch, filterUsed, filters, initialData]);

  // useEffect(() => {
  //   if (filterUsed && !isSubmitting.current) {
  //     dispatch(getFilteredProjects(filterUsed));
  //   }
  // }, [filterUsed, dispatch]);

  const onSubmit = (data: any) => {
    sessionStorage.setItem(StorageKeysEnum.CURRENT_PAGE_PROJECT_LIST, String(0));
    isSubmitting.current = true;
    const formDataArray = [];
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (Array.isArray(data[key])) {
          const isRangeModified = data[key].some((value: any, index: any) => value !== initialData[key][index]);
          if (isRangeModified) {
            formDataArray.push([key, data[key]]);
          }
        } else if (data[key] !== initialData[key]) {
          formDataArray.push([key, data[key]]);
        }
      }
    }

    setFilterParams([...formDataArray]);
    setChipsFormData([...formDataArray]);

    const filterDto: FilterDto = {};

    if (data.idProgetto) {
      filterDto.idProgetto = Number(data.idProgetto);
    }
    if (data.dateRange && data.dateRange.length > 0) {
      filterDto.inizio = new Date(data.dateRange[0]);
      filterDto.fine = new Date(data.dateRange[1]);
    }
    if (data.nomeProgetto) {
      filterDto.nomeProgetto = data.nomeProgetto;
    }
    if (data.stato) {
      filterDto.stato = data.stato.map((status: string) => status.toUpperCase().replace(/\s+/g, '_'));
    }
    if (data.nomeCliente) {
      filterDto.nomeCliente = data.nomeCliente;
    }
    if (data.businessManager) {
      filterDto.businessManager = data.businessManager;
    }
    if (data.projectManager) {
      filterDto.projectManager = data.projectManager;
    }
    if (data.tipologia) {
      filterDto.tipologia = data.tipologia;
    }
    if (data.priceRange && data.priceRange.length >= 0 && (filterDto.prezzoDa !== null || filterDto.prezzoA !== null)) {
      filterDto.prezzoDa = data.priceRange[0];
      filterDto.prezzoA = data.priceRange[1];
    }
    if (data.flagPlaceholderPlanned) {
      filterDto.flagPlaceholderPlanned = data.flagPlaceholderPlanned;
    }
    if (data.quoteId) {
      filterDto.quoteId = data.quoteId;
    }
    if (data.opportunityId) {
      filterDto.opportunityId = data.opportunityId;
    }
    if (!idContract && !isAssociableProjects) {
      dispatch(getFilteredProjects(filterDto))
        .unwrap()
        .then((res: any) => {});
    }
    if (idContract) {
      const payload = { filterDto, idContract };
      dispatch(getOtherAssociatibleCosts(payload))
        .unwrap()
        .then((res: any) => {});
    }
    if (isAssociableProjects) {
      dispatch(getAssociableProjects({ filterDto }))
        .unwrap()
        .then((res: any) => {});
    }
    handleSubmit(data);
    dispatch(saveFiltersUsed(data));
    setIsOpen(false);
  };

  const onReset = () => {
    reset(initialData);
    dispatch(saveFiltersUsed(initialData));
    setChipsFormData([]);
  };

  return { onSubmit, onReset, filterParams, chipsFormData, setChipsFormData, reset, formState };
};

export default useAccordionSubmit;
