/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PagingDtoFromJSON, PagingDtoToJSON, } from './PagingDto';
/**
 * Check if a given object implements the FatturaRicevutaSearchDto interface.
 */
export function instanceOfFatturaRicevutaSearchDto(value) {
    return true;
}
export function FatturaRicevutaSearchDtoFromJSON(json) {
    return FatturaRicevutaSearchDtoFromJSONTyped(json, false);
}
export function FatturaRicevutaSearchDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'fkFatturaRicevuta': json['fkFatturaRicevuta'] == null ? undefined : json['fkFatturaRicevuta'],
        'numeroOrdine': json['numeroOrdine'] == null ? undefined : json['numeroOrdine'],
        'fkAzienda': json['fkAzienda'] == null ? undefined : json['fkAzienda'],
        'denominazioneAzienda': json['denominazioneAzienda'] == null ? undefined : json['denominazioneAzienda'],
        'fkFornitore': json['fkFornitore'] == null ? undefined : json['fkFornitore'],
        'denominazioneFornitore': json['denominazioneFornitore'] == null ? undefined : json['denominazioneFornitore'],
        'dataFatturaDa': json['dataFatturaDa'] == null ? undefined : (new Date(json['dataFatturaDa'])),
        'dataFatturaA': json['dataFatturaA'] == null ? undefined : (new Date(json['dataFatturaA'])),
        'frCausale': json['frCausale'] == null ? undefined : json['frCausale'],
        'frProtocollo': json['frProtocollo'] == null ? undefined : json['frProtocollo'],
        'frReverseCharge': json['frReverseCharge'] == null ? undefined : json['frReverseCharge'],
        'excludefattureAssociate': json['excludefattureAssociate'] == null ? undefined : json['excludefattureAssociate'],
        'paging': json['paging'] == null ? undefined : PagingDtoFromJSON(json['paging']),
    };
}
export function FatturaRicevutaSearchDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'fkFatturaRicevuta': value['fkFatturaRicevuta'],
        'numeroOrdine': value['numeroOrdine'],
        'fkAzienda': value['fkAzienda'],
        'denominazioneAzienda': value['denominazioneAzienda'],
        'fkFornitore': value['fkFornitore'],
        'denominazioneFornitore': value['denominazioneFornitore'],
        'dataFatturaDa': value['dataFatturaDa'] == null ? undefined : ((value['dataFatturaDa']).toISOString().substring(0, 10)),
        'dataFatturaA': value['dataFatturaA'] == null ? undefined : ((value['dataFatturaA']).toISOString().substring(0, 10)),
        'frCausale': value['frCausale'],
        'frProtocollo': value['frProtocollo'],
        'frReverseCharge': value['frReverseCharge'],
        'excludefattureAssociate': value['excludefattureAssociate'],
        'paging': PagingDtoToJSON(value['paging']),
    };
}
