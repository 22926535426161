import { ContractOrderOutputDto } from '@api/client';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { formatCurrency } from 'shared/utils/common.utils';

interface Props {
  order: ContractOrderOutputDto;
}

const ActiveOrderDataTable: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation();

  if (order) {
    return (
      <div className="table-responsive table-bordered mt-3">
        <Table className={'mt-0 mb-0'}>
          <thead className="bg-red">
            <tr>
              <th className={`align-middle`} scope="col">
                <span>{'Order'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'Tot. Amount'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'A. Revenues'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'Invoices issued'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'FdE'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'Remaining amount'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'Link Doc'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'Link Order'}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="d-inline-flex align-items-center">
                  <p className="my-auto">{`(${order.idExternalContract})${order.object}`}</p>
                </span>
              </td>
              <td className="text-end">
                <span className="d-inline-flex align-items-center">
                  <p className="my-auto">{formatCurrency(order.contractValue)}€</p>
                </span>
              </td>
              <td className="text-end">
                <span className="d-inline-flex align-items-center">
                  <p className="my-auto">{formatCurrency(order.ordARicavi)}€</p>
                </span>
              </td>
              <td>
                <span className="d-inline-flex align-items-center">
                  <p className="my-auto">{'-'}</p>
                  {/*ENRICO INVOICES ISSUED*/}
                </span>
              </td>
              <td>
                <span className="d-inline-flex align-items-center">
                  <p className="my-auto">{order.fde || '-'}</p>
                </span>
              </td>
              <td>
                <span className="d-inline-flex align-items-center">
                  <p className="my-auto">{formatCurrency(order.remainingBalance)}€</p>
                </span>
              </td>
              <td>
                {order.linkDocumentation ? (
                  <a href={order.linkDocumentation} target="_blank" rel="noreferrer">
                    <i className="icon-noun-link text-blue-links fs-6" />
                  </a>
                ) : (
                  '-'
                )}
              </td>
              <td>
                {order.linkOrdine ? (
                  <a href={order.linkOrdine} target="_blank" rel="noreferrer">
                    <i className="icon-noun-link text-blue-links fs-6" />
                  </a>
                ) : (
                  '-'
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '4rem' }}>
        {t('No order details')}
      </div>
    );
  }
};

export default ActiveOrderDataTable;
