import {
  ContractOrderOutputDto,
  ContractOrderOutputListDto,
  ContractOrderSFSearchInputDto,
  FatturaEmessaDto,
  FatturaEmessaSearchDto,
  ProjectCicloPassivoOutputDto,
} from '@api/client';
import { createReducer } from '@reduxjs/toolkit';
import {
  getActiveCycleFattureEmesse,
  getAssociableProjects,
  saveFiltersActiveAssociableInvoicesUsed,
  saveFiltersOrdersSfUsed,
  searchActiveOrders,
  searchActiveOrdersSf,
} from './actions';

interface InitialState {
  activeOrdersSf: ContractOrderOutputListDto | undefined;
  activeCycleOrdersAttivi: ContractOrderOutputDto[]; //  searchOrdersAttivi
  activeCycleFattureEmesse: FatturaEmessaDto[];
  associableProjects: ProjectCicloPassivoOutputDto[];
  associableInvoices: FatturaEmessaDto[];
  filtersOrdersSfUsed: ContractOrderSFSearchInputDto | undefined;
  filtersActiveAssociableInvoicesUsed: FatturaEmessaSearchDto | undefined;
}

const initialState: InitialState = {
  activeOrdersSf: undefined,
  activeCycleOrdersAttivi: [],
  activeCycleFattureEmesse: [],
  associableProjects: [],
  associableInvoices: [],
  filtersOrdersSfUsed: undefined,
  filtersActiveAssociableInvoicesUsed: undefined,
};

export const activeCycleReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(searchActiveOrdersSf.fulfilled, (state, action) => {
      state.activeOrdersSf = action.payload;
    })
    .addCase(searchActiveOrders.fulfilled, (state, action) => {
      state.activeCycleOrdersAttivi = action.payload?.contractOrderOutputDtoList || [];
    })
    .addCase(getActiveCycleFattureEmesse.fulfilled, (state, action) => {
      const type = action.meta.arg.type;
      if (type === 'associated') {
        state.activeCycleFattureEmesse = action.payload?.content || [];
      } else {
        state.associableInvoices = action.payload?.content || [];
      }
    })
    .addCase(getAssociableProjects.fulfilled, (state, action) => {
      state.associableProjects = action.payload?.projectCicloPassivoOutputDtoList || [];
    })
    .addCase(saveFiltersOrdersSfUsed, (state, action) => {
      state.filtersOrdersSfUsed = action.payload;
    })
    .addCase(saveFiltersActiveAssociableInvoicesUsed, (state, action) => {
      state.filtersActiveAssociableInvoicesUsed = action.payload;
    });
});
