import { ProjectLightInfoDto } from '@api/client';
import { useAppDispatch } from 'core/store/hooks';
import { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AppModal from 'shared/design-system/components/app-modal';
import { deleteOrderProjectAssociation, searchActiveOrders } from '../../../_redux/actions';
import { AppToastService } from 'shared/design-system/components/app-toast/app-toast.service';
import NoData from 'shared/design-system/components/no-data-box';
import { formatCurrency } from 'shared/utils/common.utils';

interface Props {
  projects: ProjectLightInfoDto[];
  orderId: string | undefined;
  numericOrderId: number | undefined;
}

const ActiveOrderAssociatedProjectsTable: React.FC<Props> = ({ projects, orderId, numericOrderId }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isRemoveAssociationModalVisible, setIsRemoveAssociationModalVisible] = useState(false);
  const [selectedProject, setSelectedProject] = useState<ProjectLightInfoDto | null>(null);

  const handleRemoveAssociation = (project: ProjectLightInfoDto) => {
    const { projectId, intranetId, projectName } = project;
    if (!projectId || !orderId || !numericOrderId) return;
    dispatch(deleteOrderProjectAssociation({ orderId: numericOrderId, projectId })).then(() => {
      setSelectedProject(null);
      AppToastService.success(t(`Project (${intranetId}) ${projectName} removed successfully`));
      dispatch(searchActiveOrders({ contractOrderSearchDto: { cicloAttivoOrderNumber: orderId } }));
    });
  };

  if (projects.length) {
    return (
      <div className="table-responsive table-bordered mt-3">
        <Table className={'mt-0 mb-0'}>
          <thead className="bg-red">
            <tr>
              <th className={`align-middle`} scope="col">
                <span>{'Project'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'Tot. Amount'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'A. Revenues'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'Invoices issued'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'CL/AM'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'PL'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'CC'}</span>
              </th>
              <th className={`align-middle`} scope="col">
                <span>{'Link'}</span>
              </th>
              <th className={`align-middle`} scope="col" style={{ maxWidth: '80px' }}>
                <span>{'Action'}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project) => (
              <tr key={project.projectId}>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{`(${project.intranetId}) ${project.projectName}`}</p>
                  </span>
                </td>
                <td className="text-end">
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{formatCurrency(project.totAmount)}</p>
                  </span>
                </td>
                <td className="text-end">
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{formatCurrency(project.actualRevenue)}</p>
                  </span>
                </td>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{'-'}</p>
                  </span>
                </td>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{project.strutturaFunzione?.nome || '-'}</p>
                  </span>
                </td>
                <td>
                  <span className="d-inline-flex align-items-center">
                    <p className="my-auto">{project.denominazioneResponsabileProgetto || '-'}</p>
                  </span>
                </td>
                <td>{project.strutturaFunzione?.idParent?.nome}</td>
                <td>-</td>
                <td className="text-center">
                  <Button
                    className="btn text-blue-links p-0"
                    type="button"
                    variant="icon"
                    onClick={() => {
                      setSelectedProject(project);
                      setIsRemoveAssociationModalVisible(true);
                    }}
                  >
                    <i className="fas fa-circle-minus text-blue-links" />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <AppModal
          setShow={setIsRemoveAssociationModalVisible}
          show={isRemoveAssociationModalVisible}
          title="Remove association"
          cancelText={t('common.cancel')}
          confirmText={t('common.confirm')}
          onCancel={() => setIsRemoveAssociationModalVisible(false)}
          onConfirm={() => {
            handleRemoveAssociation(selectedProject!);
            setIsRemoveAssociationModalVisible(false);
          }}
        >
          <p>{'Are you sure you want to remove this association?'}</p>
        </AppModal>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center mt-3">
        <NoData msg={t('No associated projects')} />
      </div>
    );
  }
};

export default ActiveOrderAssociatedProjectsTable;
