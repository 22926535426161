export enum ValueType {
  EURO = '€',
  NULL = '',
  PERCENTUALE = '%',
  GIORNI = 'gg',
}

export enum StructureTypes {
  BUSINESS = 'business',
  DELIVERY = 'delivery',
}

export enum TrackPagesGA4Enum {
  PROJECT_LIST = 'project-list',
  PROJECT_DETAIL = 'project-detail',
  CDG = 'cdg-management-tools',
  CL = 'cl-management-tools',
  PL = 'pl-management-tools',
  DASHBOARD_PROJECTS = 'dashboard-projects',
  DASHBOARD_RESOURCES = 'dashboard-resources-planning',
  DASHBOARD_UNDERBOOKING = 'dashboard-underbooking',
  DASHBOARD_OVERBOOKING = 'dashboard-overbooking',
  PASSIVE_CYCLE = 'passive-cycle',
  ACTIVE_CYCLE = 'active-cycle',
}

export enum DelegateTypesEnum {
  PROJECT = 'project',
  HCC = 'hcc',
}

export enum LocationFilterEnum {
  PROJECT_LIST = 'project-list',
  MT_PL = 'pl',
  MT_CL = 'cl',
  MT_CDG = 'cdg',
  ACTIVE_CYCLE = 'active-cycle',
  ACTIVE_CYCLE_INVOICES = 'active-cycle-invoices',
  PASSIVE_CYCLE = 'passive-cycle',
  DASHBOARD_PROJECTS = 'dashboard-projects',
  DASHBOARD_RESOURCES = 'dashboard-resources-planning',
  BILLS_ACTIVE_CYCLE = 'bills-active-cycle',
  BILLS_PASSIVE_CYCLE = 'bills-passive-cycle',
}
