/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as runtime from '../runtime';
import { ActiveOrderProjectFromJSON, AssociateActiveOrderProjectDtoToJSON, CicloPassivoFieldsDtoFromJSON, ContractOrderOutputDtoFromJSON, ContractOrderOutputListDtoFromJSON, ContractOrderSFSearchInputDtoToJSON, ContractOrderSearchDtoToJSON, CreateContractOrderAttivoDtoToJSON, FatturaEmessaPagedDtoFromJSON, FatturaEmessaSearchDtoToJSON, FilterDtoToJSON, ProjectCicloPassivoOutputListDtoFromJSON, ProjectLightInfoDtoFromJSON, } from '../models/index';
/**
 *
 */
export class CicloAttivoControllerApi extends runtime.BaseAPI {
    /**
     * Permette di associare un ordine della vista OrderSF a un progetto esistente.
     * Associa un ordine SF a un progetto attivo
     */
    associateOrderWithProjectRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['associateActiveOrderProjectDto'] == null) {
                throw new runtime.RequiredError('associateActiveOrderProjectDto', 'Required parameter "associateActiveOrderProjectDto" was null or undefined when calling associateOrderWithProject().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-attivo/associate-order-project`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: AssociateActiveOrderProjectDtoToJSON(requestParameters['associateActiveOrderProjectDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ActiveOrderProjectFromJSON(jsonValue));
        });
    }
    /**
     * Permette di associare un ordine della vista OrderSF a un progetto esistente.
     * Associa un ordine SF a un progetto attivo
     */
    associateOrderWithProject(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.associateOrderWithProjectRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Crea un nuovo ordine
     */
    createCicloAttivo1Raw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['createContractOrderAttivoDto'] == null) {
                throw new runtime.RequiredError('createContractOrderAttivoDto', 'Required parameter "createContractOrderAttivoDto" was null or undefined when calling createCicloAttivo1().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-attivo/create-ciclo-attivo`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: CreateContractOrderAttivoDtoToJSON(requestParameters['createContractOrderAttivoDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ContractOrderOutputDtoFromJSON(jsonValue));
        });
    }
    /**
     * Crea un nuovo ordine
     */
    createCicloAttivo1(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.createCicloAttivo1Raw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Permette di eliminare l\'associazione tra un ordine della vista OrderSF e un progetto esistente.
     * Elimina l\'associazione tra un ordine SF e un progetto attivo
     */
    deleteOrderProjectAssociationRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['orderId'] == null) {
                throw new runtime.RequiredError('orderId', 'Required parameter "orderId" was null or undefined when calling deleteOrderProjectAssociation().');
            }
            if (requestParameters['projectId'] == null) {
                throw new runtime.RequiredError('projectId', 'Required parameter "projectId" was null or undefined when calling deleteOrderProjectAssociation().');
            }
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-attivo/delete-order-project-association/{orderId}/{projectId}`.replace(`{${"orderId"}}`, encodeURIComponent(String(requestParameters['orderId']))).replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters['projectId']))),
                method: 'DELETE',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.VoidApiResponse(response);
        });
    }
    /**
     * Permette di eliminare l\'associazione tra un ordine della vista OrderSF e un progetto esistente.
     * Elimina l\'associazione tra un ordine SF e un progetto attivo
     */
    deleteOrderProjectAssociation(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.deleteOrderProjectAssociationRaw(requestParameters, initOverrides);
        });
    }
    /**
     * Recupera tutti i possibili valori che le fatture possono assumere
     * Possibili valori nelle select del ciclo attivo
     */
    getCicloAttivoFieldsRaw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-attivo/fields`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => CicloPassivoFieldsDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera tutti i possibili valori che le fatture possono assumere
     * Possibili valori nelle select del ciclo attivo
     */
    getCicloAttivoFields(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getCicloAttivoFieldsRaw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Restituisce una lista di informazioni sui progetti da associare a fatture attive
     * OOttiene informazioni per select sui progetti da associare a fatture attive
     */
    getProjectLightInfoFattureAttive1Raw(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const queryParameters = {};
            const headerParameters = {};
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-attivo/progetti-fatture-attive`,
                method: 'GET',
                headers: headerParameters,
                query: queryParameters,
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProjectLightInfoDtoFromJSON));
        });
    }
    /**
     * Restituisce una lista di informazioni sui progetti da associare a fatture attive
     * OOttiene informazioni per select sui progetti da associare a fatture attive
     */
    getProjectLightInfoFattureAttive1(initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getProjectLightInfoFattureAttive1Raw(initOverrides);
            return yield response.value();
        });
    }
    /**
     * Recupera tutti gli othercost
     * Progetti da associare
     */
    getProjects1Raw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['filterDto'] == null) {
                throw new runtime.RequiredError('filterDto', 'Required parameter "filterDto" was null or undefined when calling getProjects1().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-attivo/projects`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: FilterDtoToJSON(requestParameters['filterDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ProjectCicloPassivoOutputListDtoFromJSON(jsonValue));
        });
    }
    /**
     * Recupera tutti gli othercost
     * Progetti da associare
     */
    getProjects1(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.getProjects1Raw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Ricerca contratti
     */
    searchContractOrders2Raw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['contractOrderSearchDto'] == null) {
                throw new runtime.RequiredError('contractOrderSearchDto', 'Required parameter "contractOrderSearchDto" was null or undefined when calling searchContractOrders2().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-attivo/search-orders-attivi`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ContractOrderSearchDtoToJSON(requestParameters['contractOrderSearchDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ContractOrderOutputListDtoFromJSON(jsonValue));
        });
    }
    /**
     * Ricerca contratti
     */
    searchContractOrders2(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.searchContractOrders2Raw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Ricerca ordini SF
     */
    searchContractOrdersSFRaw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['contractOrderSFSearchInputDto'] == null) {
                throw new runtime.RequiredError('contractOrderSFSearchInputDto', 'Required parameter "contractOrderSFSearchInputDto" was null or undefined when calling searchContractOrdersSF().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-attivo/search-orders-sf`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: ContractOrderSFSearchInputDtoToJSON(requestParameters['contractOrderSFSearchInputDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => ContractOrderOutputListDtoFromJSON(jsonValue));
        });
    }
    /**
     * Ricerca ordini SF
     */
    searchContractOrdersSF(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.searchContractOrdersSFRaw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
    /**
     * Consente di ottenere tutte le fatture emesse utilizzando filtri dinamici
     * Ricerca fatture emesse con filtri
     */
    searchFattureEmesse1Raw(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            if (requestParameters['fatturaEmessaSearchDto'] == null) {
                throw new runtime.RequiredError('fatturaEmessaSearchDto', 'Required parameter "fatturaEmessaSearchDto" was null or undefined when calling searchFattureEmesse1().');
            }
            const queryParameters = {};
            const headerParameters = {};
            headerParameters['Content-Type'] = 'application/json';
            if (this.configuration && this.configuration.accessToken) {
                const token = this.configuration.accessToken;
                const tokenString = yield token("Bearer", ["Bearer"]);
                if (tokenString) {
                    headerParameters["Authorization"] = `Bearer ${tokenString}`;
                }
            }
            const response = yield this.request({
                path: `/ciclo-attivo/emesse/search`,
                method: 'POST',
                headers: headerParameters,
                query: queryParameters,
                body: FatturaEmessaSearchDtoToJSON(requestParameters['fatturaEmessaSearchDto']),
            }, initOverrides);
            return new runtime.JSONApiResponse(response, (jsonValue) => FatturaEmessaPagedDtoFromJSON(jsonValue));
        });
    }
    /**
     * Consente di ottenere tutte le fatture emesse utilizzando filtri dinamici
     * Ricerca fatture emesse con filtri
     */
    searchFattureEmesse1(requestParameters, initOverrides) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.searchFattureEmesse1Raw(requestParameters, initOverrides);
            return yield response.value();
        });
    }
}
