/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PagingDtoFromJSON, PagingDtoToJSON, } from './PagingDto';
/**
 * Check if a given object implements the ContractOrderSFSearchInputDto interface.
 */
export function instanceOfContractOrderSFSearchInputDto(value) {
    return true;
}
export function ContractOrderSFSearchInputDtoFromJSON(json) {
    return ContractOrderSFSearchInputDtoFromJSONTyped(json, false);
}
export function ContractOrderSFSearchInputDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'object': json['object'] == null ? undefined : json['object'],
        'orderId': json['orderId'] == null ? undefined : json['orderId'],
        'ordNumero': json['ordNumero'] == null ? undefined : json['ordNumero'],
        'businessManagerId': json['businessManagerId'] == null ? undefined : json['businessManagerId'],
        'aziendaId': json['aziendaId'] == null ? undefined : json['aziendaId'],
        'clienteId': json['clienteId'] == null ? undefined : json['clienteId'],
        'paging': json['paging'] == null ? undefined : PagingDtoFromJSON(json['paging']),
        'stato': json['stato'] == null ? undefined : json['stato'],
    };
}
export function ContractOrderSFSearchInputDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'object': value['object'],
        'orderId': value['orderId'],
        'ordNumero': value['ordNumero'],
        'businessManagerId': value['businessManagerId'],
        'aziendaId': value['aziendaId'],
        'clienteId': value['clienteId'],
        'paging': PagingDtoToJSON(value['paging']),
        'stato': value['stato'],
    };
}
