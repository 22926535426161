/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StrutturaFunzioneFromJSON, StrutturaFunzioneToJSON, } from './StrutturaFunzione';
/**
 * Check if a given object implements the ProjectOrderDto interface.
 */
export function instanceOfProjectOrderDto(value) {
    return true;
}
export function ProjectOrderDtoFromJSON(json) {
    return ProjectOrderDtoFromJSONTyped(json, false);
}
export function ProjectOrderDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'projectId': json['projectId'] == null ? undefined : json['projectId'],
        'intranetId': json['intranetId'] == null ? undefined : json['intranetId'],
        'projectName': json['projectName'] == null ? undefined : json['projectName'],
        'associationAmount': json['associationAmount'] == null ? undefined : json['associationAmount'],
        'associationId': json['associationId'] == null ? undefined : json['associationId'],
        'denominazioneResponsabileProgetto': json['denominazioneResponsabileProgetto'] == null ? undefined : json['denominazioneResponsabileProgetto'],
        'strutturaFunzione': json['strutturaFunzione'] == null ? undefined : StrutturaFunzioneFromJSON(json['strutturaFunzione']),
    };
}
export function ProjectOrderDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'projectId': value['projectId'],
        'intranetId': value['intranetId'],
        'projectName': value['projectName'],
        'associationAmount': value['associationAmount'],
        'associationId': value['associationId'],
        'denominazioneResponsabileProgetto': value['denominazioneResponsabileProgetto'],
        'strutturaFunzione': StrutturaFunzioneToJSON(value['strutturaFunzione']),
    };
}
