import { Tabs, TabsProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { TableProjectKeys } from '../../../../../../enums/project.enum';
import LinksButton from '../../../../../../shared/design-system/components/links-button';
import {
  DettaglioProjectDto,
  DettagliProgettoDtoStatoEnum,
  ExternalCostDto,
  FilterDtoTipologiaEnum,
} from '@api/client';
import { useAppDispatch, useAppSelector } from '../../../../../../core/store/hooks';
import { getCompetenzeAnnoCorrente, getCompetenzeComplessive } from '../../_redux/actions';
import { currentProjectTypeSelector } from '../../../_redux/selectors';

interface Props {
  projectDetails: DettaglioProjectDto | null;
  readOnly: boolean | undefined;
  saveBozza: (idProject: number | undefined, costsData: ExternalCostDto[] | null | undefined) => void;
  idProject: number | undefined;
  costsData: ExternalCostDto[] | null | undefined;
  isWorkInProgress: boolean;
  isBillingDirty: boolean;
  table: TableProjectKeys;
  setTable: React.Dispatch<React.SetStateAction<TableProjectKeys>>;
  setShowModalBilling: React.Dispatch<React.SetStateAction<boolean>>;
  setNewTabValue: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const RevenuePlanCardHeader: React.FC<Props> = ({
  projectDetails,
  readOnly,
  saveBozza,
  idProject,
  costsData,
  isWorkInProgress,
  isBillingDirty,
  table,
  setTable,
  setShowModalBilling,
  setNewTabValue,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const currentProjectType = useAppSelector(currentProjectTypeSelector);

  const workInProgressTabs: TabsProps['items'] = [
    {
      key: TableProjectKeys.OVERALL_DATA,
      label: `${t('project-detail.complessive-data')}`,
      icon: <i className="fa-solid fa-chart-column" />,
    },
    {
      key: TableProjectKeys.CURRENT_YEAR_DATA,
      label: `${t('project-detail.current-year-data')}`,
      icon: <i className="fa-regular fa-clock" />,
    },
    {
      key: TableProjectKeys.TEAM,
      label: `${t('project-detail.team')}`,
      icon: <i className="fa-solid fa-people-group" />,
    },
    {
      key: TableProjectKeys.EXTERNAL_COSTS,
      label: `${t('project-detail.external-costs')}`,
      icon: <i className="fa-solid fa-euro-sign" />,
    },
    ...(currentProjectType !== FilterDtoTipologiaEnum.Costo
      ? [
          {
            key: TableProjectKeys.REVENUE_PROGRESS,
            label: `${t('project-detail.revenue-progress')}`,
            icon: <i className="fa-solid fa-percent" />,
          },
        ]
      : []),
    {
      key: TableProjectKeys.BILLING,
      label: `${t('project-detail.billing')}`,
      icon: <i className="fa-regular fa-money-bill-1" />,
    },
    {
      key: TableProjectKeys.NOTES,
      label: `${t('project-detail.notes')}`,
      icon: <i className="fa-regular fa-clipboard" />,
    },
  ];

  const canShowSaveDraft = () => {
    return (
      [TableProjectKeys.TEAM, TableProjectKeys.EXTERNAL_COSTS].includes(table) &&
      !isWorkInProgress &&
      projectDetails?.dettaglioOfferta?.stato !== DettagliProgettoDtoStatoEnum.IntranetPending
    );
  };

  const onChangeTab = (key: TableProjectKeys) => {
    if (isBillingDirty) {
      setShowModalBilling(true);
      setNewTabValue(key);
      return;
    }
    setTable(key);
  };

  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="col-8">
        <Tabs
          defaultActiveKey={TableProjectKeys.TEAM}
          items={workInProgressTabs}
          className="text-blue-links"
          onChange={(key: string) => onChangeTab(key as TableProjectKeys)}
        />
      </div>

      {canShowSaveDraft() && (
        <LinksButton
          className="btn-sm btn-primary btn-links px-2 ms-2"
          isDisabled={readOnly}
          onClick={() => saveBozza(idProject, costsData)}
        >
          <div className="d-flex align-items-center">
            <i className="fas fa-rotate-right pe-2" />
            {t('project-detail.save-draft')}
          </div>
        </LinksButton>
      )}
      {[TableProjectKeys.OVERALL_DATA, TableProjectKeys.CURRENT_YEAR_DATA].includes(table) && (
        <LinksButton
          className="btn-sm btn-primary btn-links px-2 ms-2"
          isDisabled={readOnly}
          onClick={() =>
            dispatch(
              table === TableProjectKeys.OVERALL_DATA
                ? getCompetenzeComplessive(idProject!)
                : getCompetenzeAnnoCorrente(idProject!)
            )
          }
        >
          <div className="d-flex align-items-center">
            <i className="fas fa-rotate-right pe-2" />
            {t('project-detail.refresh-data')}
          </div>
        </LinksButton>
      )}
    </div>
  );
};

export default RevenuePlanCardHeader;
