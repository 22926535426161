import { BASE_PATH_PROD, BASE_URL_SVIL } from '../../env';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '.';
import AuthService from '../auth/auth.service';
import { AppToastService } from '../../shared/design-system/components/app-toast/app-toast.service';
import { ErrorDto, instanceOfErrorDto } from '@api/client';
import { errorMessages } from '../../enums/error-messages';
import { UrlEnum } from '../../enums/url.enum';
import { hideErrorToast, showErrorToast } from './actions';

const getConfiguration = (Configuration: any, extra: any, getState: () => RootState) => {
  const c = new Configuration();

  const token = AuthService.getToken();

  const configuration: any = {
    basePath: window.location.hostname !== UrlEnum.HOSTNAME_PRODUCTION ? BASE_URL_SVIL : BASE_PATH_PROD,
    middleware: c.middleware,
    fetchApi: c.fetchApi,
    queryParamsStringify: c.queryParamsStringify,
    headers: {},
    // headers: { 'Access-Control-Allow-Origin': '*' },
    accessToken: () => token,
  };

  return configuration;
};

function Utf8ArrayToStr(array: Uint8Array) {
  var out, i, len, c;
  var char2, char3;

  out = '';
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12:
      case 13:
        // 110x xxxx   10xx xxxx
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
        break;
      case 14:
        // 1110 xxxx  10xx xxxx  10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0));
        break;
    }
  }
  return out;
}

function JSONTryParse(str: string): Object {
  try {
    return JSON.parse(str);
  } catch (e) {
    return {
      error: `JSONTryParse ${e}`,
      error_code: str,
      error_description: str,
    };
  }
}

function isErrorResponseDto(error: Object): boolean {
  return error.hasOwnProperty('message') && error.hasOwnProperty('statusCode');
}
function convertToErrorDto(errorObject: any): any | null {
  if (instanceOfErrorDto(errorObject)) {
    return errorObject as ErrorDto;
  } else if (errorObject && typeof errorObject === 'object') {
    return {
      message: errorObject.title,
      errorType: errorObject.description,
      statusCode: errorObject.errorCode,
    };
  } else {
    return null;
  }
}
const isAuthenticate = AuthService.isAuthenticated();

export const decodeError = async (error: Response): Promise<any> => {
  let errorType: ErrorDto | null = null;

  if (error?.body) {
    const errorTextReadable = await error?.body?.getReader().read();
    if (errorTextReadable.value) {
      const errorTextStr = Utf8ArrayToStr(errorTextReadable.value);
      const errorObject = errorTextStr ? JSONTryParse(errorTextStr) : {};
      errorType = convertToErrorDto(errorObject);
    }
  }

  if (errorType) {
    return errorType;
  } else {
    return {
      title: "Si e' verificato un errore generico",
      description: '',
      errorCode: '999',
    };
  }
};
export const errorHandler = async (
  error: any,
  dispatch: ThunkDispatch<RootState, any, AnyAction>,
  getState: () => RootState,
  decoded?: boolean
) => {
  const errorResponse = decoded ? error : await decodeError(error.response);

  setTimeout(() => {
    const state = getState();
    if (state.general.showError) return;

    console.error('errorResponse', errorResponse);
    const { description } = errorResponse;
    const errorDescription =
      (errorMessages as Record<string, { title: string; description: string }>)[errorResponse.description]
        ?.description ||
      description ||
      'An error occurred';

    switch (error?.response?.status) {
      case 401:
        setTimeout(() => {
          AuthService.logout(true);
        });
        // AppToastService.error(`Error - Your session has expired or you are not authorized to perform this action.`);
        break;
      default:
        AppToastService.error(`Error - ${errorDescription} `);
        break;
    }
    dispatch(showErrorToast());

    setTimeout(() => {
      dispatch(hideErrorToast());
    }, 5000);
  }, 500);
};

// export const errorHandler = async (
// 	error: any,
// 	dispatch: ThunkDispatch<RootState, any, AnyAction>,
// 	decoded?: boolean
// ) => {
// 	console.log(
// 		"errorHandler",
// 		error,
// 		error.response,
// 		error.constructor,
// 		error.constructor.name
// 	);
// 	// const errorResponse: GenericResponse = decoded
// 	// 	? error
// 	// 	: await decodeError(error.response);
// 	const errorResponse = decoded ? error : await decodeError(error.response);
// 	console.log("errorResponse", errorResponse);
// 	// dispatch(setGeneralError(errorResponse));

// 	// toast.error(
// 	// 	errorResponse?.statusCode == 401
// 	// 		? "Sessione scaduta"
// 	// 		: errorResponse?.message,
// 	// 	{
// 	// 		position: "top-center",
// 	// 		autoClose: 3000,
// 	// 		hideProgressBar: false,
// 	// 		closeOnClick: true,
// 	// 		pauseOnHover: true,
// 	// 		draggable: true,
// 	// 		progress: 0,
// 	// 	}
// 	// );
// };

export default getConfiguration;
