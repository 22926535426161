import { RevenueMonthDto } from '@api/client';
import { Input, InputNumber, Tooltip } from 'antd';
import { Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  formatCurrency,
  formatCurrencyNumber,
  getFormattedDateHeader,
} from '../../../../../../shared/utils/common.utils';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useGetProgressTableData from '../../_hooks/revenue-progress-table/useGetProgressTableData';
import useProgressManagement from '../../_hooks/revenue-progress-table/useProgressManagement';
import { RevenueAllocation, RevenueEnum } from '../../_models/revenueAllocation';
import { useEffect, useRef } from 'react';
import useRevenueProgressValidation from '../../_hooks/revenue-progress-table/useRevenueProgressValidation';

interface Props {
  revenueList: RevenueMonthDto[] | undefined;
  isWorkInProgress: boolean;
  readOnly: boolean | undefined;
  importoOfferta: number;
  refresh: boolean;
  setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  setYears: React.Dispatch<React.SetStateAction<Array<number | undefined>>>;
  setRevenueProgressValidation: React.Dispatch<React.SetStateAction<boolean>>;
}

const RevenueProgress: React.FC<Props> = ({
  revenueList,
  isWorkInProgress,
  readOnly,
  importoOfferta,
  refresh,
  setRefresh,
  setYears,
  setRevenueProgressValidation,
}) => {
  const { t } = useTranslation();
  revenueList = revenueList?.slice();
  const tableRef = useRef<HTMLDivElement>(null);

  const { revenueAllocations, revenueAllocationsShown, setRevenueAllocations, setRevenueAllocationsShown } =
    useGetProgressTableData(revenueList || [], refresh, setRefresh, setYears);

  useRevenueProgressValidation(revenueAllocations, setRevenueProgressValidation);

  const { handleChangeAllocation } = useProgressManagement(revenueAllocations, setRevenueAllocations);

  const isTotalValid = (index: number, revenueEnum: RevenueEnum) => {
    const revenueType =
      revenueEnum === RevenueEnum.CumulativeRevenuePercentage ? RevenueEnum.RevenuePerMonthPercentage : index;
    const revenue = revenueAllocations?.[revenueType];
    const target = revenueEnum === RevenueEnum.RevenuePerMonth ? importoOfferta : 100;
    if (!revenue) return false;
    return revenue.revenueList.reduce((acc, el) => acc + (el.amount ?? 0), 0) <= target + 0.01;
  };

  const getTooltipTotal = (index: number, revenue: RevenueAllocation) => {
    const { revenueList, revenueEnum, revenueUnit } = revenue;
    const target = revenueEnum === RevenueEnum.RevenuePerMonth ? importoOfferta : 100;
    const difference = revenueList.reduce((acc, el) => acc + (el.amount ?? 0), 0) - target;
    return `${difference > 0 ? '+' : ''}${formatCurrency(Number(difference.toFixed(2)))} ${revenueUnit}`;
  };

  useEffect(() => {
    const monthsWorked = revenueList?.filter((el) => el.consolidato).length ?? 0;
    scrollToRight(monthsWorked * 100);
  }, []);

  const scrollToRight = (px: number) => {
    setTimeout(() => {
      if (tableRef.current) {
        tableRef.current.scrollBy({
          top: 0,
          left: px,
          behavior: 'smooth',
        });
      }
    }, 100);
  };

  return (
    <div
      ref={tableRef}
      className="d-flex table-responsive table-bordered"
      style={{ maxHeight: 'calc(100vh - 420px)', overflowY: 'auto', overflowX: 'auto' }}
    >
      <Table className={'revenue-table sticky-table mt-0 mb-0'} style={{ maxWidth: '200px' }}>
        <thead className="fixed-sticky-header">
          <tr>
            <th className="text-left" style={{ minWidth: '250px' }}>
              <span>{t('Revenue type')}</span>
            </th>

            <th className="text-left bg-disabled" style={{ minWidth: '150px' }}>
              <span>{t('A+F')}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {revenueAllocations?.map((revenue, index) => (
            <tr key={'row-revenues-€' + index}>
              <td
                className="text-left py-0"
                style={{ minWidth: '200px', maxWidth: '200px' }}
                colSpan={revenue.canShowTotal ? 1 : 2}
              >
                <span>{revenue.revenueType}</span>
              </td>

              {revenue.canShowTotal && (
                <td className="text-left p-0 bg-disabled" key={'A+F-' + index}>
                  <Input
                    readOnly
                    className="bg-disabled"
                    style={{ background: 'transparent', border: 'none' }}
                    value={
                      revenue.revenueEnum === RevenueEnum.CumulativeRevenuePercentage
                        ? formatCurrency(revenue.revenueList[revenue.revenueList.length - 1]?.amount) +
                          ' ' +
                          revenue.revenueUnit
                        : formatCurrency(
                            Number(revenue.revenueList.reduce((acc, el) => acc + (el.amount ?? 0), 0)),
                            2
                          ) +
                          ' ' +
                          revenue.revenueUnit
                    }
                    suffix={
                      <Tooltip
                        title={isTotalValid(index, revenue.revenueEnum) ? '' : getTooltipTotal(index, revenue)}
                        color="red"
                        key={`suffix-tooltip-${index}`}
                      >
                        <ExclamationCircleOutlined
                          style={{ color: !isTotalValid(index, revenue.revenueEnum) ? 'red' : 'transparent' }}
                        />
                      </Tooltip>
                    }
                  />
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </Table>

      <Table className={'revenue-table mt-0 mb-0'}>
        <thead>
          <tr className="fixed-scroll-header">
            {revenueAllocations?.[0].revenueList?.map((allocation: RevenueMonthDto, index: number) => (
              <th
                className={`text-left ${allocation.consolidato && 'bg-disabled'}`}
                scope="col"
                key={'revenue_month' + index}
                style={{ minWidth: '100px', maxWidth: '100px' }}
              >
                <span>{getFormattedDateHeader(allocation?.month, allocation?.year)}</span>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {revenueAllocations?.map((revenue, index) => (
            <tr key={'row-revenue-€' + index}>
              {revenue.revenueList?.map((allocation: RevenueMonthDto, index: number) => (
                <td
                  className={`text-center p-0 ${allocation.consolidato && 'bg-disabled'}`}
                  key={'allocation-' + index}
                >
                  <InputNumber
                    className={`w-100 ${(allocation.amount || 0) > importoOfferta ? 'red-label' : ''}`}
                    key={'GU-per-month-' + index}
                    value={allocation.amount ?? 0}
                    decimalSeparator=","
                    readOnly={readOnly}
                    disabled={allocation.consolidato || readOnly || allocation.editable === false || revenue.readOnly}
                    min={0}
                    step={0.01}
                    precision={2}
                    onChange={(e) => handleChangeAllocation(e, allocation.month, allocation.year, revenue.revenueEnum)}
                    variant="borderless"
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default RevenueProgress;
