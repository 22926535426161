import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../core/store/hooks';
import { getPassiveCycleOptions } from '../_redux/actions';
import { passiveCycleOptionsSelector } from '../_redux/selectors';
import {
  mappedExpenseItemEnumList,
  mappedOrderContractStatusEnumList,
  MappedOrderOptions,
  mappedOrderProcessingStatusEnumList,
  mappedOrderTypeEnumList,
  OrderOption,
} from '../../../../enums/orderOptions';

const useGetPassiveCycleOptions = () => {
  const dispatch = useAppDispatch();
  const passiveCycleOptions = useAppSelector(passiveCycleOptionsSelector);
  const [mappedPassiveCycleOptions, setMappedPassiveCycleOptions] = useState<MappedOrderOptions>();

  useEffect(() => {
    if (!passiveCycleOptions) dispatch(getPassiveCycleOptions());
  }, [dispatch]);

  useEffect(() => {
    if (passiveCycleOptions) {
      const {
        expenseItemEnumList,
        orderContractStatusEnumList,
        orderProcessingStatusEnumList,
        orderTypeEnumList,
        fornitoriList,
        orderCategoryList,
        aziendaList,
        sedeList,
        clienteList,
        businessManager,
      } = passiveCycleOptions || {};
      const mapItems = (enumList: string[] | undefined, mappedList: OrderOption[] | undefined) =>
        enumList?.map((item) => ({
          value: item,
          label: mappedList?.find((mappedItem) => mappedItem.value === item)?.label || item,
        })) || [];
      setMappedPassiveCycleOptions({
        mappedExpenseItemEnumList: mapItems(expenseItemEnumList, mappedExpenseItemEnumList),
        mappedOrderContractStatusEnumList: mapItems(orderContractStatusEnumList, mappedOrderContractStatusEnumList),
        mappedOrderProcessingStatusEnumList: mapItems(
          orderProcessingStatusEnumList,
          mappedOrderProcessingStatusEnumList
        ),
        mappedOrderTypeEnumList: mapItems(orderTypeEnumList, mappedOrderTypeEnumList),
        fornitoriList,
        orderCategoryList,
        aziendaList,
        sedeList,
        clienteList,
        businessManager,
      });
    }
  }, [passiveCycleOptions]);

  return { mappedPassiveCycleOptions };
};

export default useGetPassiveCycleOptions;
