import { CategoryDto, CreateUpdateProjectQuoteDto, SearchFilterDto } from '@api/client';
import { createReducer } from '@reduxjs/toolkit';
import {
  crudCreateProject,
  crudCreateUserStructureFuncionRole,
  crudEditProjectDelivery,
  crudGetCategories,
  crudGetProject,
  crudReset,
  crudUpdateProject,
  getCrudFilters,
} from './actions';
import { AppToastService } from '../../../../shared/design-system/components/app-toast/app-toast.service';

interface InitialState {
  project: CreateUpdateProjectQuoteDto | null | undefined;
  crudFilters: SearchFilterDto | null | undefined;
  crudCategories: CategoryDto[] | null | undefined;
}

const initialState: InitialState = {
  project: null,
  crudFilters: null,
  crudCategories: null,
};

export const crudReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(crudGetProject.fulfilled, (state, action) => {
      state.project = action.payload;
    })
    .addCase(getCrudFilters.fulfilled, (state, action) => {
      state.crudFilters = action.payload || null;
    })
    .addCase(crudGetCategories.fulfilled, (state, action) => {
      state.crudCategories = action.payload;
    })
    .addCase(crudUpdateProject.fulfilled, (state, action) => {
      state.project = action.payload;
      AppToastService.success(`Edited successfully`);
    })
    .addCase(crudCreateProject.fulfilled, (state, action) => {
      state.project = action.payload;
      AppToastService.success(`Created successfully`);
    })
    .addCase(crudEditProjectDelivery.fulfilled, (state, action) => {
      AppToastService.success(`Edited successfully`);
    })
    .addCase(crudCreateUserStructureFuncionRole.fulfilled, (state, action) => {
      AppToastService.success(`Created successfully`);
    })
    .addCase(crudReset, (state, action) => {
      state.project = null;
    });
});
