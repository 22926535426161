import { DettaglioProjectDto, ProfiloProfessionaleDto } from '@api/client';
import { Card } from 'react-bootstrap';
import { compareByKeys } from '../../../../../../shared/utils/common.utils';
import './styles.scss';
import { useAppSelector } from '../../../../../../core/store/hooks';
import React, { useState } from 'react';
import { TableProjectKeys } from '../../../../../../enums/project.enum';
import OtherCostsTable from '../other-costs-table';
import ProjectDetails from '../project-details';
import useGetTeamTableData from '../../_hooks/team-table/useGetTeamTableData';
import { costiSelector, costsDataSelector } from '../../_redux/selectors';
import TotalsTable from '../totals-table';
import { useTranslation } from 'react-i18next';
import useGetOtherCostsTableData from '../../_hooks/other-costs/useGetOtherCostsTableData';
import AppNote from '../../../../../../shared/design-system/components/notes';
import ModalAction from '../modal-action';
import BillingPlan from '../billing-plan';
import useValidationDescriptionCosts from '../../_hooks/other-costs/useValidationDescriptionCosts';
import RevenuePlanCardHeader from '../revenue-plan-card-header';
import useHandleVisibleColumns from '../../_hooks/team-table/useHandleVisibleColumns';
import useHandleVisibleRows from '../../_hooks/useHandleVisibleRows';
import useSynchronizedScroll from '../../_hooks/useSynchronizedScroll';
import TeamTable from '../team-table';
import useSaveDraft from '../../_hooks/useSaveDraft';
import RevenueProgress from '../revenue-progress';

interface Props {
  projectDetails: DettaglioProjectDto | null;
  roleList: ProfiloProfessionaleDto[];
  idProject: number | undefined;
  isWorkInProgress: boolean;
  setTotalGuValidation: React.Dispatch<React.SetStateAction<boolean>>;
  setTotalCostsValidation: React.Dispatch<React.SetStateAction<boolean>>;
  setDraftValidation: React.Dispatch<React.SetStateAction<boolean>>;
  setCostDescriptionValidation: React.Dispatch<React.SetStateAction<boolean>>;
  setAssociatedCostsValidation: React.Dispatch<React.SetStateAction<boolean>>;
  setRevenueProgressValidation: React.Dispatch<React.SetStateAction<boolean>>;
}

const RevenuePlan: React.FC<Props> = ({
  projectDetails,
  roleList,
  idProject,
  isWorkInProgress,
  setTotalGuValidation,
  setTotalCostsValidation,
  setDraftValidation,
  setCostDescriptionValidation,
  setAssociatedCostsValidation,
  setRevenueProgressValidation,
}: Props) => {
  const { t } = useTranslation();
  let { staffList, revenuePlan, otherCosts, readOnly, baselineOfferta, revenueList, dettaglioOfferta, mesiProgetto } =
    projectDetails!!;
  staffList = staffList?.slice().sort(compareByKeys('userId'));
  const costList = useAppSelector(costiSelector);
  const costsData = useAppSelector(costsDataSelector);

  const [table, setTable] = React.useState<TableProjectKeys>(TableProjectKeys.TEAM);
  const [isTotalsTableCollapsed, setIsTotalsTableCollapsed] = useState<boolean>(true);
  const [isBillingDirty, setIsBillingDirty] = useState(false);
  const [showModalBilling, setShowModalBilling] = useState(false);
  const [newTabValue, setNewTabValue] = useState<string | undefined>(undefined);

  const {
    fullAllocations,
    setFullAllocations,
    fullAllocationsShown,
    totalsRevenuePlanShown,
    refresh,
    collaborators,
    years,
    setYears,
    filterAllocationsByYear,
    currentYear,
    totalGUPlanned,
    setTotalGUPlanned,
    totalsRevenuePlan,
    setRefresh,
  } = useGetTeamTableData(staffList, revenuePlan, mesiProgetto, otherCosts);

  const { saveBozza } = useSaveDraft(fullAllocations, setDraftValidation, setRefresh);

  const { visibleColumns, setVisibleColumns } = useHandleVisibleColumns();
  const { visibleRows, setVisibleRows } = useHandleVisibleRows();

  const { otherCostsTableData } = useGetOtherCostsTableData(otherCosts, costList, refresh);

  useValidationDescriptionCosts({ otherCostsTableData, setCostDescriptionValidation });

  const { teamTableRef, totalsTableRef, otherCostsTableRef, parentScrollLeft, handleParentScroll, handleChildScroll } =
    useSynchronizedScroll(fullAllocationsShown, years, table);

  return (
    <>
      <Card className="table-wrapper revenue-card">
        <Card.Header className="card-header-border py-0">
          <RevenuePlanCardHeader
            projectDetails={projectDetails}
            readOnly={readOnly}
            idProject={idProject}
            costsData={costsData}
            isWorkInProgress={isWorkInProgress}
            isBillingDirty={isBillingDirty}
            table={table}
            setTable={setTable}
            setShowModalBilling={setShowModalBilling}
            setNewTabValue={setNewTabValue}
            saveBozza={saveBozza}
          />
        </Card.Header>
        <Card.Body>
          {fullAllocationsShown && table === TableProjectKeys.TEAM && (
            <TeamTable
              projectDetails={projectDetails}
              idProject={idProject}
              readOnly={readOnly}
              visibleRows={visibleRows}
              visibleColumns={visibleColumns}
              fullAllocations={fullAllocations}
              fullAllocationsShown={fullAllocationsShown}
              isWorkInProgress={isWorkInProgress}
              years={years}
              roleList={roleList}
              collaborators={collaborators}
              currentYear={currentYear}
              totalGUPlanned={totalGUPlanned}
              teamTableRef={teamTableRef}
              baselineOfferta={baselineOfferta}
              isTotalsTableCollapsed={isTotalsTableCollapsed}
              setIsTotalsTableCollapsed={setIsTotalsTableCollapsed}
              setTotalGuValidation={setTotalGuValidation}
              setVisibleRows={setVisibleRows}
              setVisibleColumns={setVisibleColumns}
              setTotalGUPlanned={setTotalGUPlanned}
              handleParentScroll={handleParentScroll}
              setFullAllocations={setFullAllocations}
              setDraftValidation={setDraftValidation}
              setRefresh={setRefresh}
              setYears={setYears}
              filterAllocationsByYear={filterAllocationsByYear}
            />
          )}
          {otherCosts && table === TableProjectKeys.EXTERNAL_COSTS && (
            <OtherCostsTable
              otherCosts={otherCosts}
              years={years}
              setYears={setYears}
              isWorkInProgress={isWorkInProgress}
              setTotalCostsValidation={setTotalCostsValidation}
              setDraftValidation={setDraftValidation}
              fullAllocations={fullAllocations}
              totalsRevenuePlan={totalsRevenuePlan}
              readOnly={readOnly}
              idProject={idProject}
              otherCostsTableRef={otherCostsTableRef}
              onOtherCostsScroll={handleChildScroll}
              setCostDescriptionValidation={setCostDescriptionValidation}
              setAssociatedCostsValidation={setAssociatedCostsValidation}
            />
          )}
          {table === TableProjectKeys.NOTES && <AppNote idProject={idProject!} />}
          {table === TableProjectKeys.OVERALL_DATA && (
            <ProjectDetails projectId={idProject} keyData={TableProjectKeys.OVERALL_DATA} />
          )}
          {table === TableProjectKeys.CURRENT_YEAR_DATA && (
            <ProjectDetails projectId={idProject} keyData={TableProjectKeys.CURRENT_YEAR_DATA} />
          )}
          {table === TableProjectKeys.BILLING && (
            <BillingPlan
              projectName={dettaglioOfferta?.name}
              projectId={idProject!}
              setIsBillingDirty={setIsBillingDirty}
            />
          )}
          {table === TableProjectKeys.REVENUE_PROGRESS && (
            <RevenueProgress
              revenueList={revenueList}
              isWorkInProgress={isWorkInProgress}
              readOnly={readOnly}
              importoOfferta={baselineOfferta?.importoOfferta || 0}
              refresh={refresh}
              setRefresh={setRefresh}
              setYears={setYears}
              setRevenueProgressValidation={setRevenueProgressValidation}
            />
          )}
        </Card.Body>
        {(table === TableProjectKeys.TEAM || table === TableProjectKeys.EXTERNAL_COSTS) && (
          <TotalsTable
            table={table}
            visibleColumns={visibleColumns}
            visibleRows={visibleRows}
            fullAllocations={fullAllocations}
            fullAllocationsShown={fullAllocationsShown}
            totalsRevenuePlan={totalsRevenuePlan}
            totalsRevenuePlanShown={totalsRevenuePlanShown}
            isWorkInProgress={isWorkInProgress}
            parentScrollLeft={parentScrollLeft}
            onChildScroll={handleChildScroll}
            giorniLavorativi={projectDetails?.giorniLavorativi}
            isCollapsed={isTotalsTableCollapsed}
            setIsCollapsed={setIsTotalsTableCollapsed}
            totalsTableRef={totalsTableRef}
          />
        )}
      </Card>
      <ModalAction
        title={t('project-detail.billing')}
        actionLabel={t('common.confirm')}
        action={() => {
          setTable(newTabValue as TableProjectKeys);
          setIsBillingDirty(false);
        }}
        setShow={(value) => setShowModalBilling(value)}
        show={showModalBilling}
      >
        <p>{t('project-detail.billing-warning')}</p>
      </ModalAction>
    </>
  );
};

export default RevenuePlan;
