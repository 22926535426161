import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../core/store/hooks';
import { Modal } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import {
  AssociaFatturaProgettoRequest,
  ContractOrderOutputDto,
  ExternalCostDto,
  ProjectExternalCost,
} from '@api/client';
import LinksButton from '../../../../../../shared/design-system/components/links-button';
import React from 'react';
import PassiveCycleAssociableProjectsToBill from '../passive-cycle-associable-projects-to-bill';
import { AppToastService } from '../../../../../../shared/design-system/components/app-toast/app-toast.service';
import { associateFatturaProject } from '../../../../active-cycle/active-cycle-bills/_redux/actions';
import { ordersForBillsSelector } from '../../../_redux/selectors';

interface Props {
  setShow: (show: boolean) => void;
  show: boolean;
  receivedBills: any[];
  indexBill: number | undefined;
  passiveOrderDetail: ContractOrderOutputDto | undefined;
}

const AssociateCostsToBillModal: React.FC<Props> = ({
  show,
  setShow,
  receivedBills,
  indexBill,
  passiveOrderDetail,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [originalProjects, setOriginalProjects] = useState<ProjectExternalCost[]>([]);
  const passiveOrders = useAppSelector(ordersForBillsSelector);

  useEffect(() => {
    if (show) {
      setOriginalProjects(passiveOrderDetail?.projectExternalCost || []);
    }
  }, [passiveOrderDetail]);

  useEffect(() => {
    console.log('indexBill', indexBill);
  }, [indexBill]);

  const handleAssociateCost = (e: any, cost: ExternalCostDto, project: ProjectExternalCost) => {
    setOriginalProjects((prev) => {
      return prev.map((p) => {
        if (p.projectId === project.projectId) {
          return {
            ...p,
            externalCosts: p.externalCosts?.map((c) => {
              if (c.costType === cost.costType && c.groupId === cost.groupId) {
                return {
                  ...c,
                  isAssociated: e.target.checked,
                };
              }
              return cost;
            }),
          };
        }
        return p;
      });
    });
  };

  const handleChangeAmount = (e: any, cost: ExternalCostDto, project: ProjectExternalCost) => {
    setOriginalProjects((prev) => {
      return prev.map((p) => {
        if (p.projectId === project.projectId) {
          return {
            ...p,
            externalCosts: p.externalCosts?.map((c: ExternalCostDto) => {
              if (c.costType === cost.costType && c.groupId === cost.groupId) {
                return {
                  ...c,
                  contractAmount: e,
                };
              }
              return cost;
            }),
          };
        }
        return p;
      });
    });
  };

  const saveAssociation = () => {
    if (indexBill === undefined) return;
    const targetBill = receivedBills[indexBill];
    const payload: AssociaFatturaProgettoRequest = {
      associaFattureProgettoDtoList: {
        fattureProgettoDtoList: [
          {
            pkFattura: targetBill.id.value,
            dettagli: originalProjects.flatMap((project) =>
              project.externalCosts
                ? project.externalCosts
                    .filter((el) => el.isAssociated)
                    .map((cost) => ({
                      idOrdine: passiveOrders.find((el) => el.idExternalContract === targetBill.numeroOrdine.value)?.id,
                      idProject: project.projectId!,
                      idCostType: cost.costType?.id!,
                      idGroup: cost.groupId!,
                      amount: cost.contractAmount!,
                    }))
                : []
            ),
          },
        ],
      },
    };
    dispatch(associateFatturaProject(payload))
      .unwrap()
      .then((res) => {
        if (res) {
          AppToastService.success('Association saved');
          setShow(false);
        }
      });
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} size={'xl'} backdrop="static" keyboard={false}>
        <Modal.Header closeButton={false}>
          <Modal.Title className="title-modal-s" style={{ backgroundColor: 'f3f4f7', fontSize: '1em' }}>
            {'Association of costs to the bill'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {originalProjects.map((project) => {
            return (
              <React.Fragment key={`associable_project_${project.projectId}`}>
                <PassiveCycleAssociableProjectsToBill
                  project={project}
                  handleChangeAssociated={handleAssociateCost}
                  handleChangeAmount={handleChangeAmount}
                />
              </React.Fragment>
            );
          })}
          <div className="d-flex justify-content-end">
            <LinksButton className="btn btn-links-outline" onClick={() => setShow(false)}>
              Cancel
            </LinksButton>
            <LinksButton className="btn-primary btn-links" onClick={() => saveAssociation()}>
              Associate
            </LinksButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AssociateCostsToBillModal;
