import { ContractOrderOutputDto, UserDto } from '@api/client';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { Tooltip } from 'antd';
import useGetPassiveCycleOptions from 'pages/private/passive-cycle/_hooks/useGetPassiveCycleOptions';
import AppCard from 'shared/design-system/components/app-card';
import { formatCurrency } from 'shared/utils/common.utils';

interface Props {
  order: ContractOrderOutputDto;
  allUsers: UserDto[] | null;
}

const ActiveCycleOrderData: React.FC<Props> = ({ order, allUsers }) => {
  const { t } = useTranslation();
  const { mappedPassiveCycleOptions } = useGetPassiveCycleOptions();

  return (
    <AppCard title={t('Order data')} className="border-left-primary mt-3">
      <Row>
        <Col>
          <div className="span-values">
            <div className="head">{t('Order')}</div>
            <div className="value">{order.idExternalContract || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Account')}</div>
            <div className="value">{order.denominazioneCliente || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Legal entity')}</div>
            <div className="value">{order.legalEntity?.nome || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Start date')}</div>
            <div className="value">{order.startDate?.toLocaleDateString('it-IT') || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('End date')}</div>
            <div className="value">{order.endDate?.toLocaleDateString('it-IT') || '-'}</div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Status')}</div>
            <div className="value">
              {mappedPassiveCycleOptions?.mappedOrderContractStatusEnumList?.find(
                (el) => el.value === order.contractStatus?.value
              )?.label || '-'}
            </div>
          </div>
        </Col>
        <Col>
          <div className="span-values">
            <div className="head">{t('Business Structure')}</div>
            <div className="value">{order.strutturaFunzione?.nome || '-'}</div>
          </div>
        </Col>
      </Row>
    </AppCard>
  );
};

export default ActiveCycleOrderData;
