/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { StrutturaFunzioneFromJSON, StrutturaFunzioneToJSON, } from './StrutturaFunzione';
/**
 * Check if a given object implements the RptProject interface.
 */
export function instanceOfRptProject(value) {
    return true;
}
export function RptProjectFromJSON(json) {
    return RptProjectFromJSONTyped(json, false);
}
export function RptProjectFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'pkProgetto': json['pkProgetto'] == null ? undefined : json['pkProgetto'],
        'fkProgettoIntranet': json['fkProgettoIntranet'] == null ? undefined : json['fkProgettoIntranet'],
        'fkType': json['fkType'] == null ? undefined : json['fkType'],
        'denominazioneProgetto': json['denominazioneProgetto'] == null ? undefined : json['denominazioneProgetto'],
        'dataInizioProgetto': json['dataInizioProgetto'] == null ? undefined : (new Date(json['dataInizioProgetto'])),
        'dataFineProgetto': json['dataFineProgetto'] == null ? undefined : (new Date(json['dataFineProgetto'])),
        'fkOpportunity': json['fkOpportunity'] == null ? undefined : json['fkOpportunity'],
        'fkStrutturaDelivery': json['fkStrutturaDelivery'] == null ? undefined : json['fkStrutturaDelivery'],
        'denominazioneStrutturaDelivery': json['denominazioneStrutturaDelivery'] == null ? undefined : json['denominazioneStrutturaDelivery'],
        'fkStrutturaBusiness': json['fkStrutturaBusiness'] == null ? undefined : json['fkStrutturaBusiness'],
        'fkCliente': json['fkCliente'] == null ? undefined : json['fkCliente'],
        'fkAzienda': json['fkAzienda'] == null ? undefined : json['fkAzienda'],
        'pkQuote': json['pkQuote'] == null ? undefined : json['pkQuote'],
        'oftRicavi': json['oftRicavi'] == null ? undefined : json['oftRicavi'],
        'fkQuoteSF': json['fkQuoteSF'] == null ? undefined : json['fkQuoteSF'],
        'fkContratto': json['fkContratto'] == null ? undefined : json['fkContratto'],
        'oftCosti': json['oftCosti'] == null ? undefined : json['oftCosti'],
        'prjARicaviAnno': json['prjARicaviAnno'] == null ? undefined : json['prjARicaviAnno'],
        'prjFRicaviAnno': json['prjFRicaviAnno'] == null ? undefined : json['prjFRicaviAnno'],
        'prjAFRicaviAnno': json['prjAFRicaviAnno'] == null ? undefined : json['prjAFRicaviAnno'],
        'prjACostiAnno': json['prjACostiAnno'] == null ? undefined : json['prjACostiAnno'],
        'prjFCostiAnno': json['prjFCostiAnno'] == null ? undefined : json['prjFCostiAnno'],
        'prjAFCostiAnno': json['prjAFCostiAnno'] == null ? undefined : json['prjAFCostiAnno'],
        'prjACostiHRAnno': json['prjACostiHRAnno'] == null ? undefined : json['prjACostiHRAnno'],
        'prjFCostiHRAnno': json['prjFCostiHRAnno'] == null ? undefined : json['prjFCostiHRAnno'],
        'prjAFCostiHRAnno': json['prjAFCostiHRAnno'] == null ? undefined : json['prjAFCostiHRAnno'],
        'prjACostiEsterniAnno': json['prjACostiEsterniAnno'] == null ? undefined : json['prjACostiEsterniAnno'],
        'prjFCostiEsterniAnno': json['prjFCostiEsterniAnno'] == null ? undefined : json['prjFCostiEsterniAnno'],
        'prjAFCostiEsterniAnno': json['prjAFCostiEsterniAnno'] == null ? undefined : json['prjAFCostiEsterniAnno'],
        'prjAGUAnno': json['prjAGUAnno'] == null ? undefined : json['prjAGUAnno'],
        'prjAFteAnno': json['prjAFteAnno'] == null ? undefined : json['prjAFteAnno'],
        'prjFGUAnno': json['prjFGUAnno'] == null ? undefined : json['prjFGUAnno'],
        'prjAFGUAnno': json['prjAFGUAnno'] == null ? undefined : json['prjAFGUAnno'],
        'prjAFFteAnno': json['prjAFFteAnno'] == null ? undefined : json['prjAFFteAnno'],
        'prjARateAnno': json['prjARateAnno'] == null ? undefined : json['prjARateAnno'],
        'prjAFRateAnno': json['prjAFRateAnno'] == null ? undefined : json['prjAFRateAnno'],
        'prjTMarginiAnno': json['prjTMarginiAnno'] == null ? undefined : json['prjTMarginiAnno'],
        'prjAMarginiPercAnno': json['prjAMarginiPercAnno'] == null ? undefined : json['prjAMarginiPercAnno'],
        'prjARicavi': json['prjARicavi'] == null ? undefined : json['prjARicavi'],
        'prjFRicavi': json['prjFRicavi'] == null ? undefined : json['prjFRicavi'],
        'prjAFRicavi': json['prjAFRicavi'] == null ? undefined : json['prjAFRicavi'],
        'prjACosti': json['prjACosti'] == null ? undefined : json['prjACosti'],
        'prjFCosti': json['prjFCosti'] == null ? undefined : json['prjFCosti'],
        'prjAFCosti': json['prjAFCosti'] == null ? undefined : json['prjAFCosti'],
        'prjACostiAnniPrecedenti': json['prjACostiAnniPrecedenti'] == null ? undefined : json['prjACostiAnniPrecedenti'],
        'prjACostiHR': json['prjACostiHR'] == null ? undefined : json['prjACostiHR'],
        'prjFCostiHR': json['prjFCostiHR'] == null ? undefined : json['prjFCostiHR'],
        'prjAFCostiHR': json['prjAFCostiHR'] == null ? undefined : json['prjAFCostiHR'],
        'prjACostiHRAnniPrecedenti': json['prjACostiHRAnniPrecedenti'] == null ? undefined : json['prjACostiHRAnniPrecedenti'],
        'prjACostiEsterni': json['prjACostiEsterni'] == null ? undefined : json['prjACostiEsterni'],
        'prjFCostiEsterni': json['prjFCostiEsterni'] == null ? undefined : json['prjFCostiEsterni'],
        'prjAFCostiEsterni': json['prjAFCostiEsterni'] == null ? undefined : json['prjAFCostiEsterni'],
        'prjACostiEsterniAnniPrecedenti': json['prjACostiEsterniAnniPrecedenti'] == null ? undefined : json['prjACostiEsterniAnniPrecedenti'],
        'prjAGU': json['prjAGU'] == null ? undefined : json['prjAGU'],
        'prjFGU': json['prjFGU'] == null ? undefined : json['prjFGU'],
        'prjAFGU': json['prjAFGU'] == null ? undefined : json['prjAFGU'],
        'prjRRicavi': json['prjRRicavi'] == null ? undefined : json['prjRRicavi'],
        'prjNPRicavi': json['prjNPRicavi'] == null ? undefined : json['prjNPRicavi'],
        'prjARate': json['prjARate'] == null ? undefined : json['prjARate'],
        'prjAFRate': json['prjAFRate'] == null ? undefined : json['prjAFRate'],
        'prjAMargini': json['prjAMargini'] == null ? undefined : json['prjAMargini'],
        'prjTMargini': json['prjTMargini'] == null ? undefined : json['prjTMargini'],
        'prjTMarginiPerc': json['prjTMarginiPerc'] == null ? undefined : json['prjTMarginiPerc'],
        'prjAMarginiPerc': json['prjAMarginiPerc'] == null ? undefined : json['prjAMarginiPerc'],
        'prjRRicaviInizioAnno': json['prjRRicaviInizioAnno'] == null ? undefined : json['prjRRicaviInizioAnno'],
        'prjARicaviAnniPrecedenti': json['prjARicaviAnniPrecedenti'] == null ? undefined : json['prjARicaviAnniPrecedenti'],
        'prjFRicaviAnniFuturi': json['prjFRicaviAnniFuturi'] == null ? undefined : json['prjFRicaviAnniFuturi'],
        'prjStatus': json['prjStatus'] == null ? undefined : json['prjStatus'],
        'prjBacklog': json['prjBacklog'] == null ? undefined : json['prjBacklog'],
        'fkResponsabileProgetto': json['fkResponsabileProgetto'] == null ? undefined : json['fkResponsabileProgetto'],
        'denominazioneResponsabileProgetto': json['denominazioneResponsabileProgetto'] == null ? undefined : json['denominazioneResponsabileProgetto'],
        'strutturaFunzione': json['strutturaFunzione'] == null ? undefined : StrutturaFunzioneFromJSON(json['strutturaFunzione']),
    };
}
export function RptProjectToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'pkProgetto': value['pkProgetto'],
        'fkProgettoIntranet': value['fkProgettoIntranet'],
        'fkType': value['fkType'],
        'denominazioneProgetto': value['denominazioneProgetto'],
        'dataInizioProgetto': value['dataInizioProgetto'] == null ? undefined : ((value['dataInizioProgetto']).toISOString().substring(0, 10)),
        'dataFineProgetto': value['dataFineProgetto'] == null ? undefined : ((value['dataFineProgetto']).toISOString().substring(0, 10)),
        'fkOpportunity': value['fkOpportunity'],
        'fkStrutturaDelivery': value['fkStrutturaDelivery'],
        'denominazioneStrutturaDelivery': value['denominazioneStrutturaDelivery'],
        'fkStrutturaBusiness': value['fkStrutturaBusiness'],
        'fkCliente': value['fkCliente'],
        'fkAzienda': value['fkAzienda'],
        'pkQuote': value['pkQuote'],
        'oftRicavi': value['oftRicavi'],
        'fkQuoteSF': value['fkQuoteSF'],
        'fkContratto': value['fkContratto'],
        'oftCosti': value['oftCosti'],
        'prjARicaviAnno': value['prjARicaviAnno'],
        'prjFRicaviAnno': value['prjFRicaviAnno'],
        'prjAFRicaviAnno': value['prjAFRicaviAnno'],
        'prjACostiAnno': value['prjACostiAnno'],
        'prjFCostiAnno': value['prjFCostiAnno'],
        'prjAFCostiAnno': value['prjAFCostiAnno'],
        'prjACostiHRAnno': value['prjACostiHRAnno'],
        'prjFCostiHRAnno': value['prjFCostiHRAnno'],
        'prjAFCostiHRAnno': value['prjAFCostiHRAnno'],
        'prjACostiEsterniAnno': value['prjACostiEsterniAnno'],
        'prjFCostiEsterniAnno': value['prjFCostiEsterniAnno'],
        'prjAFCostiEsterniAnno': value['prjAFCostiEsterniAnno'],
        'prjAGUAnno': value['prjAGUAnno'],
        'prjAFteAnno': value['prjAFteAnno'],
        'prjFGUAnno': value['prjFGUAnno'],
        'prjAFGUAnno': value['prjAFGUAnno'],
        'prjAFFteAnno': value['prjAFFteAnno'],
        'prjARateAnno': value['prjARateAnno'],
        'prjAFRateAnno': value['prjAFRateAnno'],
        'prjTMarginiAnno': value['prjTMarginiAnno'],
        'prjAMarginiPercAnno': value['prjAMarginiPercAnno'],
        'prjARicavi': value['prjARicavi'],
        'prjFRicavi': value['prjFRicavi'],
        'prjAFRicavi': value['prjAFRicavi'],
        'prjACosti': value['prjACosti'],
        'prjFCosti': value['prjFCosti'],
        'prjAFCosti': value['prjAFCosti'],
        'prjACostiAnniPrecedenti': value['prjACostiAnniPrecedenti'],
        'prjACostiHR': value['prjACostiHR'],
        'prjFCostiHR': value['prjFCostiHR'],
        'prjAFCostiHR': value['prjAFCostiHR'],
        'prjACostiHRAnniPrecedenti': value['prjACostiHRAnniPrecedenti'],
        'prjACostiEsterni': value['prjACostiEsterni'],
        'prjFCostiEsterni': value['prjFCostiEsterni'],
        'prjAFCostiEsterni': value['prjAFCostiEsterni'],
        'prjACostiEsterniAnniPrecedenti': value['prjACostiEsterniAnniPrecedenti'],
        'prjAGU': value['prjAGU'],
        'prjFGU': value['prjFGU'],
        'prjAFGU': value['prjAFGU'],
        'prjRRicavi': value['prjRRicavi'],
        'prjNPRicavi': value['prjNPRicavi'],
        'prjARate': value['prjARate'],
        'prjAFRate': value['prjAFRate'],
        'prjAMargini': value['prjAMargini'],
        'prjTMargini': value['prjTMargini'],
        'prjTMarginiPerc': value['prjTMarginiPerc'],
        'prjAMarginiPerc': value['prjAMarginiPerc'],
        'prjRRicaviInizioAnno': value['prjRRicaviInizioAnno'],
        'prjARicaviAnniPrecedenti': value['prjARicaviAnniPrecedenti'],
        'prjFRicaviAnniFuturi': value['prjFRicaviAnniFuturi'],
        'prjStatus': value['prjStatus'],
        'prjBacklog': value['prjBacklog'],
        'fkResponsabileProgetto': value['fkResponsabileProgetto'],
        'denominazioneResponsabileProgetto': value['denominazioneResponsabileProgetto'],
        'strutturaFunzione': StrutturaFunzioneToJSON(value['strutturaFunzione']),
    };
}
