import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ContractOrderOutputDto, FatturaEmessaDto, FatturaRicevutaDto, ProjectLightInfoDto } from '@api/client';
import { format } from 'date-fns';
import { formatCurrency } from '../../../../../shared/utils/common.utils';
import { useAppSelector } from '../../../../../core/store/hooks';
import { fattureRicevuteSelector } from '../../../active-cycle/active-cycle-bills/_redux/selectors';
import { TableProps } from '../../../project-list/project-list-table';

export enum FieldEnum {
  ORDER,
  PROJECT,
}

const useGetAndMapReceivedBills = (
  passiveOrders: ContractOrderOutputDto[],
  projectsForPassiveBills: ProjectLightInfoDto[]
) => {
  const { t } = useTranslation();
  const [receivedBills, setReceivedBills] = useState<TableProps['data']>([]);

  const filteredBills = useAppSelector(fattureRicevuteSelector);

  useEffect(() => {
    setReceivedBills(mapBills(filteredBills?.content || []));
  }, [filteredBills]);

  const mapBills = (bills: FatturaRicevutaDto[]): TableProps['data'] => {
    return bills.map((bill) => {
      return {
        id: { label: t('ID'), value: bill.fkFatturaRicevuta, visible: true },
        denominazioneFornitore: {
          label: t('Supplier'),
          value: bill.denominazioneFornitore,
          visible: true,
        },
        dataFattura: {
          label: t('Date'),
          value: bill.dataFattura ? format(new Date(bill.dataFattura), 'dd/MM/yyyy') : '-',
          visible: true,
        },
        imponibile: {
          label: t('Amount'),
          value: `${formatCurrency(bill.frImponibile)}€`,
          visible: true,
          className: 'text-end',
        },
        frDescrizioneCausale: { label: t('Causal'), value: bill.frDescrizioneCausale, visible: true },
        azienda: { label: t('Legal entity'), value: bill.denominazioneAzienda, visible: true },
        numeroOrdine: {
          id: FieldEnum.ORDER,
          label: t('Order number'),
          value: bill.associatedOrders?.[0]?.orderExternalId,
          visible: true,
          isSelect: true,
          options: passiveOrders,
          tooltip: t('Select order'),
          width: '200px',
        },
        // progetti: {
        //   id: FieldEnum.PROJECT,
        //   label: t('Projects'),
        //   value: projectsForPassiveBills.find((el) => el.projectId === bill.associatedOrders?.[0]?.associatedProjects),
        //   visible: true,
        //   isSelect: true,
        //   options: projectsForPassiveBills,
        //   tooltip: t('Select project'),
        //   width: '200px',
        // },
        azione: {
          label: t('project-list.action'),
          value: '',
          visible: true,
          isButton: true,
          icon: 'icon-edit',
          className: 'text-center',
          tooltip: t('Associate'),
        },
      };
    });
  };
  return { receivedBills, setReceivedBills, filteredBills };
};

export default useGetAndMapReceivedBills;
