/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CodiceTypeFromJSON, CodiceTypeToJSON, } from './CodiceType';
/**
 * Check if a given object implements the CodiceCostoRicavoDto interface.
 */
export function instanceOfCodiceCostoRicavoDto(value) {
    return true;
}
export function CodiceCostoRicavoDtoFromJSON(json) {
    return CodiceCostoRicavoDtoFromJSONTyped(json, false);
}
export function CodiceCostoRicavoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'codice': json['codice'] == null ? undefined : json['codice'],
        'descrizione': json['descrizione'] == null ? undefined : json['descrizione'],
        'livello': json['livello'] == null ? undefined : json['livello'],
        'tipoId': json['tipoId'] == null ? undefined : CodiceTypeFromJSON(json['tipoId']),
    };
}
export function CodiceCostoRicavoDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'codice': value['codice'],
        'descrizione': value['descrizione'],
        'livello': value['livello'],
        'tipoId': CodiceTypeToJSON(value['tipoId']),
    };
}
