/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the CreateContractOrderAttivoDto interface.
 */
export function instanceOfCreateContractOrderAttivoDto(value) {
    return true;
}
export function CreateContractOrderAttivoDtoFromJSON(json) {
    return CreateContractOrderAttivoDtoFromJSONTyped(json, false);
}
export function CreateContractOrderAttivoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'contractOrderId': json['contractOrderId'] == null ? undefined : json['contractOrderId'],
        'contractValue': json['contractValue'] == null ? undefined : json['contractValue'],
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'fkUtente': json['fkUtente'] == null ? undefined : json['fkUtente'],
        'note': json['note'] == null ? undefined : json['note'],
        'object': json['object'] == null ? undefined : json['object'],
        'linkDocumentation': json['linkDocumentation'] == null ? undefined : json['linkDocumentation'],
        'legalEntity': json['legalEntity'] == null ? undefined : json['legalEntity'],
        'pkOrdine': json['pkOrdine'] == null ? undefined : json['pkOrdine'],
        'ordNumero': json['ordNumero'] == null ? undefined : json['ordNumero'],
    };
}
export function CreateContractOrderAttivoDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'contractOrderId': value['contractOrderId'],
        'contractValue': value['contractValue'],
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'fkUtente': value['fkUtente'],
        'note': value['note'],
        'object': value['object'],
        'linkDocumentation': value['linkDocumentation'],
        'legalEntity': value['legalEntity'],
        'pkOrdine': value['pkOrdine'],
        'ordNumero': value['ordNumero'],
    };
}
