import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'core/store';

const activeCycleReducer = (state: RootState) => state.private.activeCycle;

export const activeOrdersSfSelector = createSelector(activeCycleReducer, (activeCycle) => activeCycle.activeOrdersSf);
export const activeCycleOrdersAttiviSelector = createSelector(
  activeCycleReducer,
  (activeCycle) => activeCycle.activeCycleOrdersAttivi
);
export const activeCycleProjectsAssociatedSelector = createSelector(
  activeCycleReducer,
  (activeCycle) => activeCycle.activeCycleOrdersAttivi?.[0]?.associatedProjectActiveOrder || []
);
export const activeCycleFattureEmesseSelector = createSelector(
  activeCycleReducer,
  (activeCycle) => activeCycle.activeCycleFattureEmesse
);
export const associableProjectsActiveCycleSelector = createSelector(
  activeCycleReducer,
  (activeCycle) => activeCycle.associableProjects
);
export const associableInvoicesActiveCycleSelector = createSelector(
  activeCycleReducer,
  (activeCycle) => activeCycle.associableInvoices
);
export const filtersOrdersSfUsedSelector = createSelector(
  activeCycleReducer,
  (activeCycle) => activeCycle.filtersOrdersSfUsed
);
export const filtersActiveAssociableInvoicesUsedSelector = createSelector(
  activeCycleReducer,
  (activeCycle) => activeCycle.filtersActiveAssociableInvoicesUsed
);
