/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * @export
 */
export const DettagliProgettoDtoCategoriaEnum = {
    Corpo: 'CORPO',
    Canone: 'CANONE',
    Costo: 'COSTO',
    Consumo: 'CONSUMO'
};
/**
 * @export
 */
export const DettagliProgettoDtoStatoEnum = {
    Pending: 'PENDING',
    Planning: 'PLANNING',
    ClosedLost: 'CLOSED_LOST',
    IntranetPending: 'INTRANET_PENDING',
    WorkInProgress: 'WORK_IN_PROGRESS',
    Completed: 'COMPLETED',
    ClosedWonPlanning: 'CLOSED_WON_PLANNING',
    PlanningOk: 'PLANNING_OK',
    ControlloCdg: 'CONTROLLO_CDG',
    ControlloCl: 'CONTROLLO_CL',
    ReportingClosed: 'REPORTING_CLOSED',
    EconomicsClosed: 'ECONOMICS_CLOSED'
};
/**
 * Check if a given object implements the DettagliProgettoDto interface.
 */
export function instanceOfDettagliProgettoDto(value) {
    return true;
}
export function DettagliProgettoDtoFromJSON(json) {
    return DettagliProgettoDtoFromJSONTyped(json, false);
}
export function DettagliProgettoDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'descrizione': json['descrizione'] == null ? undefined : json['descrizione'],
        'categoria': json['categoria'] == null ? undefined : json['categoria'],
        'stato': json['stato'] == null ? undefined : json['stato'],
        'cliente': json['cliente'] == null ? undefined : json['cliente'],
        'idProjectManager': json['idProjectManager'] == null ? undefined : json['idProjectManager'],
        'externalQuoteId': json['externalQuoteId'] == null ? undefined : json['externalQuoteId'],
        'externalOpportunityId': json['externalOpportunityId'] == null ? undefined : json['externalOpportunityId'],
        'competenceCenters': json['competenceCenters'] == null ? undefined : json['competenceCenters'],
        'intranetId': json['intranetId'] == null ? undefined : json['intranetId'],
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'nonWorkableAmount': json['nonWorkableAmount'] == null ? undefined : json['nonWorkableAmount'],
        'hasOda': json['hasOda'] == null ? undefined : json['hasOda'],
        'categories': json['categories'] == null ? undefined : json['categories'],
        'periodo': json['periodo'] == null ? undefined : json['periodo'],
        'idAccountManager': json['idAccountManager'] == null ? undefined : json['idAccountManager'],
        'accountManager': json['accountManager'] == null ? undefined : json['accountManager'],
        'projectManager': json['projectManager'] == null ? undefined : json['projectManager'],
    };
}
export function DettagliProgettoDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'name': value['name'],
        'descrizione': value['descrizione'],
        'categoria': value['categoria'],
        'stato': value['stato'],
        'cliente': value['cliente'],
        'idProjectManager': value['idProjectManager'],
        'externalQuoteId': value['externalQuoteId'],
        'externalOpportunityId': value['externalOpportunityId'],
        'competenceCenters': value['competenceCenters'],
        'intranetId': value['intranetId'],
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'nonWorkableAmount': value['nonWorkableAmount'],
        'hasOda': value['hasOda'],
        'categories': value['categories'],
        'periodo': value['periodo'],
        'idAccountManager': value['idAccountManager'],
        'accountManager': value['accountManager'],
        'projectManager': value['projectManager'],
    };
}
