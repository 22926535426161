/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CategoryFromJSON, CategoryToJSON, } from './Category';
import { CodiceCostoRicavoFromJSON, CodiceCostoRicavoToJSON, } from './CodiceCostoRicavo';
/**
 * Check if a given object implements the CreateUpdateProjectDto interface.
 */
export function instanceOfCreateUpdateProjectDto(value) {
    return true;
}
export function CreateUpdateProjectDtoFromJSON(json) {
    return CreateUpdateProjectDtoFromJSONTyped(json, false);
}
export function CreateUpdateProjectDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'] == null ? undefined : json['id'],
        'idStato': json['idStato'] == null ? undefined : json['idStato'],
        'idType': json['idType'] == null ? undefined : json['idType'],
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'startDate': json['startDate'] == null ? undefined : (new Date(json['startDate'])),
        'endDate': json['endDate'] == null ? undefined : (new Date(json['endDate'])),
        'businessManager': json['businessManager'] == null ? undefined : json['businessManager'],
        'projectManager': json['projectManager'] == null ? undefined : json['projectManager'],
        'businessUnit': json['businessUnit'] == null ? undefined : json['businessUnit'],
        'client': json['client'] == null ? undefined : json['client'],
        'creationDate': json['creationDate'] == null ? undefined : (new Date(json['creationDate'])),
        'lastmodifiedDate': json['lastmodifiedDate'] == null ? undefined : (new Date(json['lastmodifiedDate'])),
        'idOpportunity': json['idOpportunity'] == null ? undefined : json['idOpportunity'],
        'idOpportunityPadre': json['idOpportunityPadre'] == null ? undefined : json['idOpportunityPadre'],
        'idContract': json['idContract'] == null ? undefined : json['idContract'],
        'compenteceCenters': json['compenteceCenters'] == null ? undefined : json['compenteceCenters'],
        'clientId': json['clientId'] == null ? undefined : json['clientId'],
        'intranetId': json['intranetId'] == null ? undefined : json['intranetId'],
        'idProjectManager': json['idProjectManager'] == null ? undefined : json['idProjectManager'],
        'idBusinessManager': json['idBusinessManager'] == null ? undefined : json['idBusinessManager'],
        'idCompetenceCenter': json['idCompetenceCenter'] == null ? undefined : json['idCompetenceCenter'],
        'idIndustryLead': json['idIndustryLead'] == null ? undefined : json['idIndustryLead'],
        'industryLead': json['industryLead'] == null ? undefined : json['industryLead'],
        'idAccountManager': json['idAccountManager'] == null ? undefined : json['idAccountManager'],
        'accountManager': json['accountManager'] == null ? undefined : json['accountManager'],
        'idDelivery': json['idDelivery'] == null ? undefined : json['idDelivery'],
        'idDeliveryUnit': json['idDeliveryUnit'] == null ? undefined : json['idDeliveryUnit'],
        'idSubDeliveryUnit': json['idSubDeliveryUnit'] == null ? undefined : json['idSubDeliveryUnit'],
        'idStrutturaBusiness': json['idStrutturaBusiness'] == null ? undefined : json['idStrutturaBusiness'],
        'idStrutturaDelivery': json['idStrutturaDelivery'] == null ? undefined : json['idStrutturaDelivery'],
        'idAzienda': json['idAzienda'] == null ? undefined : json['idAzienda'],
        'isPrjCoordinamentoFrom': json['isPrjCoordinamentoFrom'] == null ? undefined : json['isPrjCoordinamentoFrom'],
        'isPrjCoordinamentoTo': json['isPrjCoordinamentoTo'] == null ? undefined : json['isPrjCoordinamentoTo'],
        'nonWorkableAmount': json['nonWorkableAmount'] == null ? undefined : json['nonWorkableAmount'],
        'codiceCostoRicavo': json['codiceCostoRicavo'] == null ? undefined : CodiceCostoRicavoFromJSON(json['codiceCostoRicavo']),
        'codiceCosto': json['codiceCosto'] == null ? undefined : CodiceCostoRicavoFromJSON(json['codiceCosto']),
        'codiceCostoLv2': json['codiceCostoLv2'] == null ? undefined : CodiceCostoRicavoFromJSON(json['codiceCostoLv2']),
        'codiceCostoLv3': json['codiceCostoLv3'] == null ? undefined : CodiceCostoRicavoFromJSON(json['codiceCostoLv3']),
        'codiceRicavo': json['codiceRicavo'] == null ? undefined : CodiceCostoRicavoFromJSON(json['codiceRicavo']),
        'isDeleted': json['isDeleted'] == null ? undefined : json['isDeleted'],
        'categories': json['categories'] == null ? undefined : (json['categories'].map(CategoryFromJSON)),
    };
}
export function CreateUpdateProjectDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'idStato': value['idStato'],
        'idType': value['idType'],
        'name': value['name'],
        'description': value['description'],
        'startDate': value['startDate'] == null ? undefined : ((value['startDate']).toISOString()),
        'endDate': value['endDate'] == null ? undefined : ((value['endDate']).toISOString()),
        'businessManager': value['businessManager'],
        'projectManager': value['projectManager'],
        'businessUnit': value['businessUnit'],
        'client': value['client'],
        'creationDate': value['creationDate'] == null ? undefined : ((value['creationDate']).toISOString()),
        'lastmodifiedDate': value['lastmodifiedDate'] == null ? undefined : ((value['lastmodifiedDate']).toISOString()),
        'idOpportunity': value['idOpportunity'],
        'idOpportunityPadre': value['idOpportunityPadre'],
        'idContract': value['idContract'],
        'compenteceCenters': value['compenteceCenters'],
        'clientId': value['clientId'],
        'intranetId': value['intranetId'],
        'idProjectManager': value['idProjectManager'],
        'idBusinessManager': value['idBusinessManager'],
        'idCompetenceCenter': value['idCompetenceCenter'],
        'idIndustryLead': value['idIndustryLead'],
        'industryLead': value['industryLead'],
        'idAccountManager': value['idAccountManager'],
        'accountManager': value['accountManager'],
        'idDelivery': value['idDelivery'],
        'idDeliveryUnit': value['idDeliveryUnit'],
        'idSubDeliveryUnit': value['idSubDeliveryUnit'],
        'idStrutturaBusiness': value['idStrutturaBusiness'],
        'idStrutturaDelivery': value['idStrutturaDelivery'],
        'idAzienda': value['idAzienda'],
        'isPrjCoordinamentoFrom': value['isPrjCoordinamentoFrom'],
        'isPrjCoordinamentoTo': value['isPrjCoordinamentoTo'],
        'nonWorkableAmount': value['nonWorkableAmount'],
        'codiceCostoRicavo': CodiceCostoRicavoToJSON(value['codiceCostoRicavo']),
        'codiceCosto': CodiceCostoRicavoToJSON(value['codiceCosto']),
        'codiceCostoLv2': CodiceCostoRicavoToJSON(value['codiceCostoLv2']),
        'codiceCostoLv3': CodiceCostoRicavoToJSON(value['codiceCostoLv3']),
        'codiceRicavo': CodiceCostoRicavoToJSON(value['codiceRicavo']),
        'isDeleted': value['isDeleted'],
        'categories': value['categories'] == null ? undefined : (value['categories'].map(CategoryToJSON)),
    };
}
