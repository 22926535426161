import { Col, Modal, Row } from 'react-bootstrap';
import { DatePicker, Form, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { Formik, FormikState } from 'formik';
import dayjs from 'dayjs';
import { CreateCicloAttivoRequest, CreateContractOrderDto, UserDto } from '@api/client';
import { createOrdineAttivo, getOrdiniAttivi } from '../../_redux/actions';
import { AppToastService } from '../../../../../../shared/design-system/components/app-toast/app-toast.service';
import { MappedOrderOptions } from '../../../../../../enums/orderOptions';
import { useAppDispatch } from '../../../../../../core/store/hooks';
import { filterOption } from '../../../../../../shared/utils/common.utils';
import LinksButton from '../../../../../../shared/design-system/components/links-button';

interface Props {
  setShow: (show: boolean) => void;
  show: boolean;
  mappedPassiveCycleOptions: MappedOrderOptions | undefined;
  allUsers: UserDto[] | null;
}

const AddActiveOrderModal: React.FC<Props> = ({ show, setShow, mappedPassiveCycleOptions, allUsers }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleSubmit = (values: any) => {
    const payload: CreateCicloAttivoRequest = {
      createContractOrderAttivoDto: {
        contractOrderId: values.contractOrderId,
        contractValue: values.contractValue,
        startDate: values.startDate,
        endDate: values.endDate,
        fkUtente: values.fkUtente,
        legalEntity: values.legalEntity,
        linkDocumentation: values.linkDocumentation,
        object: values.object,
        note: values.note,
      },
    };
    dispatch(createOrdineAttivo(payload))
      .unwrap()
      .then((res) => {
        if (res) {
          setShow(false);
          dispatch(getOrdiniAttivi());
          AppToastService.success('Order created');
        }
      });
  };

  const initialValues: Partial<FormikState<CreateContractOrderDto>> = {
    values: {
      contractOrderId: undefined,
      contractValue: undefined,
      startDate: undefined,
      endDate: undefined,
      fkUtente: undefined,
      legalEntity: undefined,
      linkDocumentation: undefined,
      object: undefined,
      note: undefined,
    },
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)} size={'xl'} backdrop="static" keyboard={false}>
        <Modal.Header closeButton={false}>
          <Modal.Title className="title-modal-s" style={{ backgroundColor: 'f3f4f7', fontSize: '1em' }}>
            {'Add Order'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik onSubmit={handleSubmit} initialValues={initialValues?.values as CreateContractOrderDto}>
            {({ handleSubmit: _handleSubmit, handleChange, setFieldValue, values, touched, errors }) => {
              const handleChangeCustom = (input: string) => (e: any) => {
                setFieldValue(input, e);
              };

              const onChangeDate = (input: string) => (date: dayjs.Dayjs) => {
                setFieldValue(input, date ? new Date(date.format('YYYY-MM-DD')) : null);
              };

              return (
                <Form onFinish={_handleSubmit}>
                  <Row>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('ID *')}</label>
                        <Form.Item
                          name={t('ID')}
                          className="mb-3"
                          initialValue={values.contractOrderId}
                          rules={[{ required: true }]}
                        >
                          <Input
                            type="number"
                            name="contractOrderId"
                            placeholder={t('Insert ID')}
                            value={values.contractOrderId}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Contract value *')}</label>
                        <Form.Item
                          name={t('Contract value')}
                          className="mb-3"
                          initialValue={values.contractValue}
                          rules={[{ required: true }]}
                        >
                          <Input
                            type="number"
                            name="contractValue"
                            placeholder={t('Insert contract value')}
                            value={values.contractValue}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Legal Entity *')}</label>
                        <Form.Item
                          name={t('Legal Entity')}
                          className="mb-3"
                          initialValue={values.legalEntity}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            className="w-100"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            placeholder={t('Select legal entity')}
                            onChange={handleChangeCustom('legalEntity')}
                            value={values.legalEntity}
                            getPopupContainer={(triggerMode) => {
                              return triggerMode.parentNode as HTMLElement;
                            }}
                          >
                            {mappedPassiveCycleOptions?.aziendaList?.map((el) => (
                              <Select.Option key={`legalEntity-${el.id}`} value={el.id}>
                                {el.nome}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Order supervisor *')}</label>
                        <Form.Item
                          name={t('Order supervisor')}
                          className="mb-3"
                          initialValue={values.fkUtente}
                          rules={[{ required: true }]}
                        >
                          <Select
                            showSearch
                            className="w-100"
                            optionFilterProp="children"
                            filterOption={filterOption}
                            allowClear
                            placeholder={t('Select order supervisor')}
                            onChange={handleChangeCustom('fkUtente')}
                            value={values.fkUtente}
                            getPopupContainer={(triggerMode) => {
                              return triggerMode.parentNode as HTMLElement;
                            }}
                          >
                            {allUsers?.map((el) => (
                              <Select.Option key={`fkUtente-${el.id}`} value={el.id}>
                                {el.nominativo}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <div className="form-group">
                        <label>{t('Start date *')}</label>
                        <Form.Item
                          name={t('Start date')}
                          className="mb-3"
                          initialValue={values.startDate ? values.startDate : null}
                          rules={[{ required: true }]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD/MM/YYYY"
                            allowClear
                            placeholder={t('Insert start date *')}
                            value={values.startDate ? dayjs(values.startDate) : null}
                            onChange={onChangeDate('startDate')}
                            suffixIcon={<i className="far fa-calendar text-blue-links" />}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={6} xs={6}>
                      <div className="form-group">
                        <label>{t('End date *')}</label>
                        <Form.Item
                          name={t('End date')}
                          className="mb-3"
                          initialValue={values.endDate ? dayjs(values.endDate) : null}
                          rules={[{ required: true }]}
                        >
                          <DatePicker
                            className="w-100"
                            format="DD/MM/YYYY"
                            allowClear
                            placeholder={t('Insert end date')}
                            value={values.endDate ? dayjs(values.endDate) : null}
                            onChange={onChangeDate('endDate')}
                            suffixIcon={<i className="far fa-calendar text-blue-links" />}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={3} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Link documentation')}</label>
                        <Form.Item
                          name={t('Link documentation')}
                          className="mb-3"
                          initialValue={values.linkDocumentation}
                        >
                          <Input
                            type="text"
                            name="linkDocumentation"
                            placeholder={t('Insert link documentation')}
                            value={values.linkDocumentation}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row></Row>
                  <Row className="align-items-center">
                    <Col lg={6} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Object *')}</label>
                        <Form.Item name={t('Object')} initialValue={values.object} rules={[{ required: true }]}>
                          <Input.TextArea
                            name="object"
                            placeholder={t('Insert object')}
                            value={values.object}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col lg={6} md={12} sm={12} xs={12}>
                      <div className="form-group">
                        <label>{t('Notes')}</label>
                        <Form.Item name={t('Notes')} initialValue={values.note}>
                          <Input.TextArea
                            name="note"
                            placeholder={t('Insert notes')}
                            value={values.note}
                            onChange={handleChange}
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  <div className="d-flex justify-content-end">
                    <LinksButton className="btn btn-links-outline" onClick={() => setShow(false)}>
                      Cancel
                    </LinksButton>
                    <LinksButton type="submit" className="btn-primary btn-links">
                      Add
                    </LinksButton>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddActiveOrderModal;
